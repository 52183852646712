import {connect} from "react-redux";
import {memo, useEffect, useRef, useState} from "react";
import {isMobile} from "react-device-detect";
import {useSearchParams} from "react-router-dom";
import {v4 as uuidv4} from "uuid";

import TextBoxMarker from "./Marker";

import {
    getPainterStartData,
    getTextToolboxMarkersLoadingState,
    getTextToolboxMarkersState,
    getTextToolboxState,
} from "../../../store/selectors";
import {
    setTextToolboxState,
    setDrawerToolboxClickedState,
    setEraserClickedState,
    setLineDrawToolboxState,
    setRulerClickedState,
    setCommentToolboxState,
    setPolygonDrawToolboxState, setStickyNotesClickedState
} from "../../../store/actions/painterStart";
import {
    setTextToolboxMarkersLoadingState,
    setTextToolboxMarkersState
} from "../../../store/actions/mapStateAction";

import {changeCursorIcon, CURSOR_TYPE} from "../../../shared/mockData";
import TextBoxIcon from "../../../assets/imgs/PaintBar/text-box-icon.svg";

const TextToolBox = (props) => {
    const {
        getPainterStartData,
        getTextToolboxState,
        setTextToolboxState,
        getTextToolboxMarkersState,
        setTextToolboxMarkersState,
        getTextToolboxMarkersLoadingState,
        setTextToolboxMarkersLoadingState,
        setDrawerToolboxClickedState,
        setEraserClickedState,
        setRulerClickedState,
        setLineDrawToolboxState,
        setCommentToolboxState,
        setPolygonDrawToolboxState,
        setStickyNotesClickedState,
        map
    } = props;

    const [searchParams] = useSearchParams ();
    const [markers, setMarkers] = useState ([]);
    const markersCopy = useRef (null); // Array
    const textBoxFunctionHandler = useRef (null); // Function Reference
    const currentMarkerRef = useRef (null); // Current Marker Reference
    const mapIsMoving = useRef (false);
    const isAnyMarkerMoving = useRef (false);
    const handleDeleteMarkerRef = useRef (null);
    const setMarkerInfoParamByIDRef = useRef (null);
    const setCurrentMarkerRef = useRef (null);
    const [screenSlideID, setScreenSlideID] = useState(null)


    if (isMobile) {
        map.on ('moveend', () => {
            mapIsMoving.current = false;
        });
        map.on ('dragstart', () => {
            mapIsMoving.current = true;
        });
    }

    const handleMapClick = (e) => {
        e.originalEvent.preventDefault ();
        e.originalEvent.stopPropagation ();

        if (isMobile && (mapIsMoving.current || isAnyMarkerMoving.current)) {
            return;
        }

        const coordinates = e.lngLat;

        const newMarker = {
            id: uuidv4 (),
            coordinates: coordinates,
        };

        setMarkers (prevState => [...prevState, newMarker]);
        setTextToolboxState(false);
    };

    const setCurrentMarker = (ref) => {
        currentMarkerRef.current = ref;
    };

    const setMarkerInfoParamByID = (id, param, value) => {
        const index = markersCopy.current.findIndex (marker => marker.id === id);
        if (index !== -1) {
            const newMarkers = [...markersCopy.current];
            newMarkers[index][param] = value;
            setMarkers (newMarkers);
        } else {
            console.error (`Marker with id ${id} not found.`);
        }
    };

    const handleDeleteMarker = (id) => {
        const copyOfMarkers = [...markersCopy.current];
        const newMarkers = copyOfMarkers.filter (marker => marker.id !== id);
        setMarkers (newMarkers);
    };

    useEffect (() => {
        markersCopy.current = [...markers];
        setTextToolboxMarkersState ([...markersCopy.current]);
    }, [markers]);

    useEffect (() => {
        if (!getTextToolboxMarkersLoadingState) {
            setMarkers (prevState => [...getTextToolboxMarkersState]);
            const id = searchParams.get("screenSlide");
            setScreenSlideID(id ?? '');
            setTextToolboxMarkersLoadingState (true);
        }
    }, [getTextToolboxMarkersLoadingState]);

    useEffect (() => {
        handleDeleteMarkerRef.current = handleDeleteMarker;
        setMarkerInfoParamByIDRef.current = setMarkerInfoParamByID;
        setCurrentMarkerRef.current = setCurrentMarker;

        return () => {
            handleDeleteMarkerRef.current = null;
            setMarkerInfoParamByIDRef.current = null;
            setCurrentMarkerRef.current = null;

        }
    }, []);

    const handleIconClick = () => {
        setDrawerToolboxClickedState(false);
        setEraserClickedState(false);
        setRulerClickedState(false);
        setPolygonDrawToolboxState(false);
        setStickyNotesClickedState(false);
        setLineDrawToolboxState(false);
        setCommentToolboxState(false);
        setTextToolboxState(!getTextToolboxState);
    }

    useEffect(() => {
        if(getPainterStartData && getTextToolboxState) {
            textBoxFunctionHandler.current = handleMapClick;
            map.on(isMobile ? "touchend" : "click", textBoxFunctionHandler.current);
            setTimeout (() => {
                changeCursorIcon (CURSOR_TYPE.TEXT_BOX)
            }, 50)
        } else {
            map.off(isMobile ? "touchend" : "click", textBoxFunctionHandler.current);
            changeCursorIcon ()
        }
    }, [getPainterStartData, getTextToolboxState])

    return (
        <>
            <div
                id="textBox_icon"
                className={`pain_items ${getTextToolboxState ? "button_active" : ""}`}
                onClick={handleIconClick}
            >
                <img src={TextBoxIcon} alt="" className="icon_img"/>
            </div>
            {
                getTextToolboxMarkersState &&
                getTextToolboxMarkersState.length !== 0 &&
                getTextToolboxMarkersState.map (marker =>
                    <TextBoxMarker
                        marker={marker}
                        key={marker.id + `${screenSlideID}`}
                        isAnyMarkerMoving={isAnyMarkerMoving}
                        id={marker.id}
                        description={marker.description}
                        color={marker.color}
                        backgroundColor={marker.backgroundColor}
                        height={marker.height}
                        width={marker.width}
                        opacity={marker.opacity}
                        rotation={marker.rotation}
                        coordinates={marker.coordinates}
                        fontSize={marker.fontSize}
                        map={map}
                        currentMarkerRef={currentMarkerRef}
                        handleDeleteMarker={handleDeleteMarkerRef.current}
                        setMarkerInfoParamByID={setMarkerInfoParamByIDRef.current}
                        setCurrentMarkerRef={setCurrentMarkerRef.current}
                    />)
            }
        </>
    );
};

const mapStateTopProps = (state) => ({
    getTextToolboxState: getTextToolboxState(state),
    getTextToolboxMarkersState: getTextToolboxMarkersState(state),
    getTextToolboxMarkersLoadingState: getTextToolboxMarkersLoadingState(state),
    getPainterStartData: getPainterStartData(state),
});

const mapDispatchToProps = {
    setTextToolboxState: setTextToolboxState,
    setDrawerToolboxClickedState: setDrawerToolboxClickedState,
    setEraserClickedState: setEraserClickedState,
    setRulerClickedState: setRulerClickedState,
    setCommentToolboxState: setCommentToolboxState,
    setTextToolboxMarkersState: setTextToolboxMarkersState,
    setTextToolboxMarkersLoadingState: setTextToolboxMarkersLoadingState,
    setLineDrawToolboxState: setLineDrawToolboxState,
    setPolygonDrawToolboxState: setPolygonDrawToolboxState,
    setStickyNotesClickedState: setStickyNotesClickedState,
};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(TextToolBox));
