import {
  SET_ARTICLES,
  LOADING_ARTICLES,
  SET_SELECTED_ARTICLE,
  SET_ARTICLE_IS_LIKED,
} from "../constants";

import { setAudioPlayingFunc } from "./audio";

import {
  getArticleAPI,
  likeArticleAPIRequest,
  unlikeArticleAPIRequest,
} from "../../service/article";

export const loadingArticles = () => ({
  type: LOADING_ARTICLES,
});

export const setArticles = (articles) => ({
  type: SET_ARTICLES,
  payload: articles,
});

export const setSelectedArticle = (article) => ({
  type: SET_SELECTED_ARTICLE,
  payload: article,
});

export const getArticleFromAPI = (id,stopFly) => (dispatch, getState) => {
  dispatch(loadingArticles());

  getArticleAPI(id).then((article) => {
    let articles = getState().articles.data;
    const openedArticles = articles.filter((x) => x.show);
    if (
      articles.find((x) => x.id == id) !== undefined ||
      typeof articles.find((x) => x.id == id) !== "undefined"
    ) {
      const indexArticle = articles.findIndex((x) => x.id === article.id);
      articles[indexArticle].show = true;
    } else {
      if (openedArticles.length > 0) {
        openedArticles.forEach((article) => {
          const indexArticle = articles.findIndex((x) => x.id === article.id);
          articles.splice(indexArticle, 1);
        });
      }
      articles.push(article);
    }
    dispatch(
      setSelectedArticle({
        id: id,
        type: article.articleData.type,
        fly: !!stopFly
      })
    );
    console.log(articles,'articles')
    // dispatch(setSearch(false))
    dispatch(setArticles(articles));
  });
};

export const collapsArticlePopup = (id) => (dispatch, getState) => {
  dispatch(loadingArticles());
  const articles = getState().articles.data;
  const foundIndex = articles.findIndex((article) => article.id === id);
  articles[foundIndex].show = !articles[foundIndex].show;
  dispatch(setArticles(articles));
  dispatch(setAudioPlayingFunc(false));
};

export const closeArticlePopup = (id) => (dispatch, getState) => {
  dispatch(loadingArticles());
  let articles, foundIndex;

  if (id === "all") {
    articles = [];
  } else {
    articles = getState().articles.data;
    foundIndex = articles.findIndex((article) => article.id === id);
    articles.splice(foundIndex, 1);
  }
  dispatch(setArticles(articles));
};
