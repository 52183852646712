import { connect } from "react-redux";
import "./style.css";
import CardBody from "../../CustomCardComponents/CardBody";
import {
    getScreenShotDataFromAPI,
    removeScreenShotCardAPI,
} from "../../../../store/actions/mapStateAction";
import {getPresentationModeData, getRemoveCardId, getSlidesData} from "../../../../store/selectors";
import {
    getMultipleSlidsByPresentationAPI,
    removeSlideAPI, setSelectedSlide,
    setSlidesItems,
    setSlidesPage
} from "../../../../store/actions/slides";
import {getPresentationAPI, removePresentationAPI, setMenu} from "../../../../store/actions/presentationMode";
import {getAllPresentationsFromAPI} from "../../../../store/actions/presentation";
import {useNavigate} from "react-router-dom";
const cardBodyStyle = {
    aspectRatio: "677/255",
    gridTemplateRows: "1fr 1fr",
    padding: "20px",
    backgroundColor: '#fff',
};
const SlideRemove = (props) => {
    const navigate = useNavigate();

    const { onClose,setSelectedSlide, closePresentation,setSlidesPage,getSlidesByPresentationID, setSlidesItems, selectedId, removeSlideAPI, getPresentationAPI,getPresentationModeData,deletePopup,removePresentationAPI,setMenu,getAllPresentationsFromAPI, openSlides } = props;

    // const queryParams = new URLSearchParams(window.location.search);
    // let pageLoc = +queryParams.get("page");


    const handleDelete = () => {

        if(deletePopup === 'slide'){
            const search = {
                page:1,
                perPage:10,
            }
            const newUrl = new URLSearchParams(window.location.search);
            setSelectedSlide(null)
            if(newUrl.has('screenSlide')){
                newUrl.delete('screenSlide')
                newUrl.delete('screenShot')
                newUrl.delete('userId')
                navigate({
                    pathname: window.location.pathname,
                    search: newUrl.toString(),
                }, { replace: true })
            }
            removeSlideAPI(selectedId)
                .then(() => {
                    setSlidesPage(1);
                    getSlidesByPresentationID([`${props.getPresentationModeData?.id}`], search,'add');
                    // openSlides(getPresentationModeData.id, true)
                    onClose()
                })
        }else{
            removePresentationAPI(getPresentationModeData.id)
                .then(resp=>{
                    getAllPresentationsFromAPI({
                        "page": 1,
                        "perPage": 1000,
                    })
                        .then(resp=> {
                            onClose()
                            closePresentation()
                        })
                })
        }
    };

    return (
        <div className="slide-remove-content">
            <CardBody hasIcon={false} styles={cardBodyStyle}>
                <div className={"slide-delete-container"}>
                    <div className="slide-title">
                        <h4>Ջնջե՞լ</h4>
                        <p>
                            Վստա՞հ եք, որ ցանկանում եք ջնջել
                        </p>
                    </div>
                    <div className="slide_zone">
                        <button
                            className="slide__cancel slide_buttons"
                            onClick={onClose}
                        >
                            Չեղարկել
                        </button>
                        <button
                            className="slide_remove slide_buttons"
                            onClick={handleDelete}
                        >
                            Ջնջել
                        </button>
                    </div>
                </div>
            </CardBody>
        </div>
    );
};

const mapStateTopProps = (state) => ({
    getRemoveCardId: getRemoveCardId(state),
    slides: getSlidesData(state),
    getPresentationModeData: getPresentationModeData(state),
});

const mapDispatchToProps = {
    removeScreenShotCardAPI: removeScreenShotCardAPI,
    getScreenShotDataFromAPI,
    setSlidesItems: setSlidesItems,
    removeSlideAPI: removeSlideAPI,
    getPresentationAPI: getPresentationAPI,
    removePresentationAPI: removePresentationAPI,
    setMenu: setMenu,
    getAllPresentationsFromAPI:getAllPresentationsFromAPI,
    setSlidesPage:setSlidesPage,
    getSlidesByPresentationID:getMultipleSlidsByPresentationAPI,
    setSelectedSlide:setSelectedSlide,


};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(SlideRemove);
