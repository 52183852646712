import React, {memo, useEffect} from "react";

const ColorCircle = ({color, status, updateColorStatus, isPaint = false}) => {

    useEffect(() => {
        if (status) updateColorStatus(color.id)
    }, []);

    return (
        <svg
            style={{
                stroke: status ? isPaint ? "#fff" : "#2c476c" : color?.color,
                strokeWidth: status ? "2" : "0",
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox={status ? "0 0 25 25" : "0 0 30 30"}
            fill={color?.color}
        >
            <circle
                cx={status ? "12.5" : "15"}
                cy={status ? "12.5" : "15"}
                r={status ? "11.25" : "14.375"}
                stroke={color?.color}
            />
            <circle
                cx={status ? "12.5" : "15"}
                cy={status ? "12.5" : "15"}
                r={status ? "10" : "10.625"}
                fill={color?.color}
            />
        </svg>
    )
}

export default memo(ColorCircle);
