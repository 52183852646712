import React, { memo, useCallback, useEffect, useRef } from 'react'
import { connect, Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { isMobile } from 'react-device-detect'

import mapboxgl from 'mapbox-gl'
import * as turf from '@turf/turf'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode'
import * as MapboxDrawWaypoint from 'mapbox-gl-draw-waypoint'

import LineDrawToolbox from './LineDrawToolbox'
import LineDrawCreateEditPopup from './LineDrawToolbox/LineDrawCreateEditPopup'
import PolygonDrawToolbox from './PolygonDrawToolbox'
import PolygonDrawCreateEditPopup from './PolygonDrawToolbox/PolygonDrawCreateEditPopup'

import { store } from '../../../store'

import {
  getCommentToolboxState,
  getDrawerToolboxClickedState,
  getEraserClickedState,
  getIsAllToolboxStatesLoaded,
  getLineDrawToolboxColors,
  getLineDrawToolboxCurrentColor,
  getLineDrawToolboxState,
  getLineDrawToolboxWeight,
  getMapboxDrawToolboxGeoJSONState,
  getPolygonDrawToolboxColors,
  getPolygonDrawToolboxCurrentFillColor,
  getPolygonDrawToolboxCurrentFillOpacity,
  getPolygonDrawToolboxCurrentStrokeColor,
  getPolygonDrawToolboxCurrentStrokeOpacity,
  getPolygonDrawToolboxState,
  getRulerClickedState,
  getStickyNotesClickedState,
  getTextToolboxState,
} from '../../../store/selectors'

import {
  setLineDrawToolboxColorPickerState,
  setLineDrawToolboxColors,
  setLineDrawToolboxCurrentColor,
  setLineDrawToolboxState,
  setLineDrawToolboxWeight,
  setPolygonDrawToolboxColorPickerState,
  setPolygonDrawToolboxColors,
  setPolygonDrawToolboxCurrentFillColor,
  setPolygonDrawToolboxCurrentFillOpacity,
  setPolygonDrawToolboxCurrentStrokeColor,
  setPolygonDrawToolboxCurrentStrokeOpacity,
  setPolygonDrawToolboxState,
} from '../../../store/actions/painterStart'

import { setMapboxDrawToolboxGeoJSONState } from '../../../store/actions/mapStateAction'

import {
  defaultFillColor,
  defaultFillOpacity,
  defaultStrokeColor,
  defaultStrokeOpacity,
  defaultWeight,
  mapboxGlDrawStyles,
} from './constants'

const MapboxDrawToolbox = (props) => {
  const {
    getStickyNotesClickedState,
    getDrawerToolboxClickedState,
    getEraserClickedState,
    getCommentToolboxState,
    getTextToolboxState,
    getRulerClickedState,
    getPolygonDrawToolboxState,
    getLineDrawToolboxState,
    getLineDrawToolboxColors,
    getMapboxDrawToolboxGeoJSONState,
    getIsAllToolboxStatesLoaded,
    getPolygonDrawToolboxColors,
    setPolygonDrawToolboxColors,
    setLineDrawToolboxColors,
    setLineDrawToolboxState,
    setLineDrawToolboxCurrentColor,
    setLineDrawToolboxWeight,
    setMapboxDrawToolboxGeoJSONState,
    setPolygonDrawToolboxState,
    setPolygonDrawToolboxCurrentStrokeColor,
    setPolygonDrawToolboxCurrentStrokeOpacity,
    setPolygonDrawToolboxCurrentFillColor,
    setPolygonDrawToolboxCurrentFillOpacity,
    setLineDrawToolboxColorPickerState,
    setPolygonDrawToolboxColorPickerState,
    hasLineDraw,
    hasPolygonDraw,
    isScreenshot,
    globalLoading,
    map,
  } = props

  const clickEvent = useRef(null)
  const selectedToolType = useRef(null)
  const selectedMapboxDrawToolFeature = useRef(null)
  // For Both LineString and Polygon logic
  const lastCreatedFeature = useRef(null)
  const lineOrPolygonDrawHoverPopupRef = useRef(null)

  // For LineString logic
  const mouseEnterAndOutTimeout = useRef(null)
  const lineDrawEditPopupRef = useRef(null)

  // For Polygon logic
  const polygonEditPopupModeRef = useRef('stroke')
  const polygonDrawEditPopupRef = useRef(null)

  const updateLineDrawColorStatus = (id) => {
    setLineDrawToolboxColors(
      getLineDrawToolboxColors.map((item) => {
        item.status = 0
        if (item.id === id) {
          item.status = 1
        }
        return item
      }),
    )
  }

  const updatePolygonDrawColorStatus = (id) => {
    setPolygonDrawToolboxColors(
      getPolygonDrawToolboxColors.map((item) => {
        item.status = 0
        if (item.id === id) {
          item.status = 1
        }
        return item
      }),
    )
  }

  const modeChanger = (mode) => {
    if (selectedMapboxDrawToolFeature.current) {
      if (mode === 'stroke') {
        polygonEditPopupModeRef.current = 'stroke'
        setPolygonDrawToolboxCurrentStrokeColor(
          selectedMapboxDrawToolFeature.current.properties.portStrokeColor ||
            defaultStrokeColor,
        )
        setPolygonDrawToolboxCurrentStrokeOpacity(
          selectedMapboxDrawToolFeature.current.properties.portStrokeOpacity ||
            defaultStrokeOpacity,
        )
      }

      if (mode === 'fill') {
        polygonEditPopupModeRef.current = 'fill'
        setPolygonDrawToolboxCurrentFillColor(
          selectedMapboxDrawToolFeature.current.properties.portFillColor ||
            defaultFillColor,
        )
        setPolygonDrawToolboxCurrentFillOpacity(
          selectedMapboxDrawToolFeature.current.properties.portFillOpacity ||
            defaultFillOpacity,
        )
      }
    } else {
      polygonEditPopupModeRef.current = 'stroke'
    }
  }

  const createHoverPopUpLineDrawToolbox = (text) => {
    const textToNum = +text
    const km = textToNum.toFixed(1)
    const miles = (textToNum / 1.609).toFixed(2)
    const container = document.createElement('div')
    container.className = 'hover-popUp-container-line-string'

    const kilometersP = document.createElement('p')
    kilometersP.className = 'hover-popUp-text'
    kilometersP.textContent = km + ' կմ'

    const milesP = document.createElement('p')
    milesP.className = 'hover-popUp-text'
    milesP.textContent = miles + ' մղոն'

    container.appendChild(kilometersP)
    container.appendChild(milesP)

    return container
  }

  const createHoverPopUpPolygonDrawToolbox = (text) => {
    function squareMetersToSquareMiles(m2) {
      const conversionFactor = 3.861e-7
      return m2 * conversionFactor
    }

    function squareMetersToSquareKilometers(m2) {
      const conversionFactor = 1e-6
      return m2 * conversionFactor
    }

    const textToNum = +text
    const km = squareMetersToSquareKilometers(textToNum).toFixed(1)

    const container = document.createElement('div')
    container.className = 'hover-popUp-container-line-string'

    const kilometersP = document.createElement('p')
    kilometersP.className = 'hover-popUp-text'
    kilometersP.textContent = km + ' կմ²'

    const milesP = document.createElement('p')
    milesP.className = 'hover-popUp-text'
    milesP.textContent = (km * 100).toFixed(0) + ' հա'

    container.appendChild(kilometersP)
    container.appendChild(milesP)

    return container
  }

  const createDistancesPropertyForLineFeatures = (e) => {
    const lineStringFeature = e.features[0]
    lineStringFeature.properties.distance = {}
    let lastDistance = 0

    for (let i = 1; i < lineStringFeature.geometry.coordinates.length; i++) {
      const from = turf.point(lineStringFeature.geometry.coordinates[i])
      const to = turf.point(lineStringFeature.geometry.coordinates[i - 1])
      const options = { units: 'kilometers' }
      const distance = turf.distance(from, to, options)
      lastDistance += distance

      lineStringFeature.properties.distance[i.toString()] = lastDistance
    }

    map.draw.add(lineStringFeature)
  }

  const createAreaPropertyForPolygonFeatures = (e) => {
    const polygonFeature = e.features[0]
    polygonFeature.properties.area = 0

    const polygon = turf.polygon(polygonFeature.geometry.coordinates)
    polygonFeature.properties.area = turf.area(polygon)

    map.draw.add(polygonFeature)
  }

  const onVertexMouseEnterHandler = useCallback(
    (e) => {
      const featureParentId = e.features[0].properties.parent
      const parentFeature = map.draw.get(featureParentId)
      selectedToolType.current = parentFeature.geometry.type
      if (selectedToolType.current === 'LineString') {
        if (
          !e.features[0]?.properties?.parent ||
          e.features[0]?.properties?.coord_path === '0' ||
          !parentFeature ||
          !parentFeature?.properties.distance ||
          isNaN(
            +parentFeature?.properties?.distance[
              e.features[0]?.properties?.coord_path
            ],
          )
        )
          return

        if (mouseEnterAndOutTimeout.current)
          clearTimeout(mouseEnterAndOutTimeout.current)

        lineOrPolygonDrawHoverPopupRef.current
          .setDOMContent(
            createHoverPopUpLineDrawToolbox(
              parentFeature?.properties?.distance[
                e.features[0]?.properties?.coord_path
              ],
            ),
          )
          .setLngLat(e.features[0].geometry.coordinates || e.lngLat)
          .addTo(map)

        if (isMobile) {
          lineOrPolygonDrawHoverPopupRef.current
            .getElement()
            .addEventListener('touchend', () => {
              onVertexMouseOutHandler()
            })
        }
        return
      }

      if (selectedToolType.current === 'Polygon') {
        if (
          !e.features[0]?.properties?.parent ||
          !parentFeature ||
          !parentFeature?.properties?.area
        )
          return
        if (mouseEnterAndOutTimeout.current)
          clearTimeout(mouseEnterAndOutTimeout.current)

        lineOrPolygonDrawHoverPopupRef.current
          .setDOMContent(
            createHoverPopUpPolygonDrawToolbox(parentFeature?.properties?.area),
          )
          .setLngLat(e.features[0].geometry.coordinates || e.lngLat)
          .addTo(map)
      }
    },
    [selectedToolType.current],
  )

  const onVertexMouseOutHandler = useCallback(() => {
    const hoverPopupContainer = document.querySelector(
      '.hover-popUp-container-line-string',
    )
    if (hoverPopupContainer) hoverPopupContainer.style.opacity = 0
    mouseEnterAndOutTimeout.current = setTimeout(() => {
      lineOrPolygonDrawHoverPopupRef.current.remove()
    }, 300)
  }, [])

  const createHoverPopUpsWithDistancesOrAreas = (e) => {
    if (
      !e.features[0].properties.distance ||
      e.features[0].properties.distance['1'] ||
      !e.features[0].properties.area
    ) {
      return
    }

    if (!isMobile) {
      map.off(
        'mouseenter',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
        onVertexMouseEnterHandler,
      )
      map.off(
        'mouseout',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
        onVertexMouseOutHandler,
      )
      map.off(
        'mouseenter',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
        onVertexMouseEnterHandler,
      )
      map.off(
        'mouseout',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
        onVertexMouseOutHandler,
      )
      map.on(
        'mouseenter',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
        onVertexMouseEnterHandler,
      )
      map.on(
        'mouseout',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
        onVertexMouseOutHandler,
      )
      map.on(
        'mouseenter',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
        onVertexMouseEnterHandler,
      )
      map.on(
        'mouseout',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
        onVertexMouseOutHandler,
      )
    }
  }

  const updateMapboxDrawFeature = (e) => {
    switch (e.type) {
      case 'draw.create':
        lastCreatedFeature.current = e.features[0]
        selectedToolType.current = e.features[0].geometry.type

        if (selectedToolType.current === 'LineString') {
          setLineDrawToolboxState(false)
          if (e.features[0].geometry.length <= 1) return
          e.features[0].properties.portColor = defaultStrokeColor
          e.features[0].properties.portWidth = defaultWeight

          setLineDrawToolboxCurrentColor(defaultStrokeColor)
          setLineDrawToolboxWeight(defaultWeight)
          createDistancesPropertyForLineFeatures(e)
          lineDrawEditPopupRef.current
            .setLngLat(clickEvent.current.lngLat)
            .addTo(map)
        }

        if (selectedToolType.current === 'Polygon') {
          setPolygonDrawToolboxState(false)
          if (e.features[0].geometry.length <= 2) return
          e.features[0].properties.portStrokeColor = defaultStrokeColor
          e.features[0].properties.portStrokeOpacity = defaultStrokeOpacity
          e.features[0].properties.portFillColor = defaultFillColor
          e.features[0].properties.portFillOpacity = defaultFillOpacity

          setPolygonDrawToolboxCurrentStrokeColor(defaultStrokeColor)
          setPolygonDrawToolboxCurrentStrokeOpacity(defaultStrokeOpacity)
          setPolygonDrawToolboxCurrentFillColor(defaultFillColor)
          setPolygonDrawToolboxCurrentFillOpacity(defaultFillOpacity)
          createAreaPropertyForPolygonFeatures(e)
          polygonDrawEditPopupRef.current
            .setLngLat(clickEvent.current.lngLat)
            .addTo(map)
        }

        createHoverPopUpsWithDistancesOrAreas(e)
        setMapboxDrawToolboxGeoJSONState(
          JSON.parse(JSON.stringify(map.draw.getAll())),
        )
        break

      case 'draw.update':
        const updatedFeature = e.features[0]

        if (updatedFeature.geometry.type === 'LineString') {
          createDistancesPropertyForLineFeatures(e)
        }

        if (updatedFeature.geometry.type === 'Polygon') {
          createAreaPropertyForPolygonFeatures(e)
        }

        map.draw.getSelected().features[0] = updatedFeature
        setMapboxDrawToolboxGeoJSONState(
          JSON.parse(JSON.stringify(map.draw.getAll())),
        )
        break
      case 'draw.delete':
        map.draw.delete(e.features[0].id)
        setMapboxDrawToolboxGeoJSONState(
          JSON.parse(JSON.stringify(map.draw.getAll())),
        )
        break
      default:
        return
    }
  }

  const mapboxDrawFeaturesPointsClickHandler = (e) => {
    if (isMobile) {
      onVertexMouseOutHandler(e)
    }

    const selectedFeature = map.draw.get(e.features[0].properties.parent)

    if (selectedFeature) {
      if (selectedFeature.geometry.type === 'LineString') {
        if (
          isMobile &&
          ((selectedFeature.geometry.coordinates.length === 2 &&
            selectedFeature.geometry.coordinates[0][0] ===
              selectedFeature.geometry.coordinates[1][0] &&
            selectedFeature.geometry.coordinates[0][1] ===
              selectedFeature.geometry.coordinates[1][1]) ||
            selectedFeature.geometry.coordinates.length < 2)
        ) {
          setLineDrawToolboxState(false)
          return
        } else {
          setLineDrawToolboxState(false)
        }

        if (
          lineDrawEditPopupRef.current &&
          lineDrawEditPopupRef.current.isOpen()
        ) {
          lineDrawEditPopupRef.current.setLngLat(e.lngLat)
        } else {
          lineDrawEditPopupRef.current.setLngLat(e.lngLat).addTo(map)
        }
      }

      if (selectedFeature.geometry.type === 'Polygon') {
        if (isMobile && selectedFeature.geometry.coordinates.length < 4) {
          setPolygonDrawToolboxState(false)
          return
        } else {
          setPolygonDrawToolboxState(false)
        }

        if (
          polygonDrawEditPopupRef.current &&
          polygonDrawEditPopupRef.current.isOpen()
        ) {
          polygonDrawEditPopupRef.current.setLngLat(e.lngLat)
        } else {
          polygonDrawEditPopupRef.current.setLngLat(e.lngLat).addTo(map)
        }
      }
    } else {
      setLineDrawToolboxState(false)
      setPolygonDrawToolboxState(false)
    }
  }

  const mapboxDrawFeaturesPointsClickStartHandler = (e) => {
    onVertexMouseEnterHandler(e)
  }

  const updateMapboxDrawFeatureRef = useRef(updateMapboxDrawFeature)
  const mapboxDrawFeaturesPointsClickHandlerRef = useRef(
    mapboxDrawFeaturesPointsClickHandler,
  )
  const mapboxDrawFeaturesPointsClickStartHandlerRef = useRef(
    mapboxDrawFeaturesPointsClickStartHandler,
  )

  const selectionChangeHandler = (selectionEvent) => {
    if (lineDrawEditPopupRef.current && lineDrawEditPopupRef.current.isOpen())
      lineDrawEditPopupRef.current.remove()
    if (
      polygonDrawEditPopupRef.current &&
      polygonDrawEditPopupRef.current.isOpen()
    )
      polygonDrawEditPopupRef.current.remove()

    if (selectionEvent.features.length) {
      selectedMapboxDrawToolFeature.current = selectionEvent.features[0]
      selectedToolType.current = selectionEvent.features[0].geometry.type

      if (selectionEvent.features[0].geometry.type === 'LineString') {
        const selectedColor = getLineDrawToolboxColors.find(
          (color) =>
            color.color === selectionEvent.features[0].properties?.portColor,
        )
        if (
          selectedColor &&
          selectedColor.color !==
            selectionEvent.features[0]?.properties?.portColor
        ) {
          updateLineDrawColorStatus(selectedColor ? selectedColor.id : null)
          // setLineDrawToolboxCurrentColor(selectionEvent.features[0]?.properties?.portColor);
          // setLineDrawToolboxWeight(selectionEvent.features[0]?.properties?.portWidth);
        }
        if (clickEvent.current) {
          setTimeout(() => {
            if (
              lineDrawEditPopupRef.current &&
              lineDrawEditPopupRef.current.isOpen()
            ) {
              lineDrawEditPopupRef.current.setLngLat(clickEvent.current.lngLat)
            } else {
              lineDrawEditPopupRef.current
                .setLngLat(clickEvent.current.lngLat)
                .addTo(map)
            }
          })
        }
      }

      if (selectionEvent.features[0].geometry.type === 'Polygon') {
        const queryRenderedFeatures = map.queryRenderedFeatures(
          clickEvent.current.point,
          {
            layers: [
              'gl-draw-polygon-stroke-active-copy.hot',
              'gl-draw-polygon-stroke-active-copy.cold',
              'gl-draw-polygon-stroke-inactive-copy.hot',
              'gl-draw-polygon-stroke-inactive-copy.cold',
            ],
          },
        )

        if (queryRenderedFeatures.length) {
          const selectedColor = getPolygonDrawToolboxColors.find(
            (color) =>
              color.color === selectionEvent.features[0].properties?.portColor,
          )
          if (
            selectedColor &&
            selectedColor.color !==
              selectionEvent.features[0]?.properties?.portColor
          ) {
            updatePolygonDrawColorStatus(
              selectedColor ? selectedColor.id : null,
            )
            // setPolygonDrawToolboxCurrentStrokeColor(selectionEvent.features[0].properties.portStrokeColor);
            // setPolygonDrawToolboxCurrentStrokeOpacity(selectionEvent.features[0].properties.portStrokeOpacity);
            // setPolygonDrawToolboxCurrentFillColor(selectionEvent.features[0].properties.portFillColor);
            // setPolygonDrawToolboxCurrentFillOpacity(selectionEvent.features[0].properties.portFillOpacity);
          }
          if (clickEvent.current) {
            setTimeout(() => {
              if (
                polygonDrawEditPopupRef.current &&
                polygonDrawEditPopupRef.current.isOpen()
              ) {
                polygonDrawEditPopupRef.current.setLngLat(
                  clickEvent.current.lngLat,
                )
              } else {
                polygonDrawEditPopupRef.current
                  .setLngLat(clickEvent.current.lngLat)
                  .addTo(map)
              }
            })
          }
        }
      }
    } else {
      map.fire('closeMapboxGlDrawPopupsAndSelection')
    }
  }

  const closeMapboxGlDrawPopupsAndSelection = useCallback((e) => {
    lineDrawEditPopupRef.current && lineDrawEditPopupRef.current.remove()
    polygonDrawEditPopupRef.current && polygonDrawEditPopupRef.current.remove()
    selectedMapboxDrawToolFeature.current = null
    selectedToolType.current = null
    if (e.notChangeMode) return
    map?.draw?.changeMode('simple_select', { featureIds: [] })
  }, [])

  const selectionChangeHandlerRef = useRef(selectionChangeHandler)

  const changeEditPopupPositionAsNeed = () => {
    const editPopupNode = lineDrawEditPopupRef.current.getElement()
    const nodeRect = editPopupNode?.getBoundingClientRect()
    if (!nodeRect) return

    const popupWidth = 180 // popup width
    const popupHeight = 30 // popup height

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    if (nodeRect.left + popupWidth > windowWidth) {
      editPopupNode.style.left = `-100px`
    } else {
      editPopupNode.style.left = '100px'
    }

    if (windowHeight + nodeRect.top < windowHeight) {
      editPopupNode.style.top = `${popupHeight * 2}px`
    } else {
      editPopupNode.style.top = '-10px'
    }
  }

  const handleClose = (type) => {
    switch (type) {
      case 'line':
        lineDrawEditPopupRef.current.remove()
        break
      case 'polygon':
        polygonDrawEditPopupRef.current.remove()
        break
      default:
        return
    }
    map.fire('closeMapboxGlDrawPopupsAndSelection')
  }

  const handleDelete = (type) => {
    map?.draw?.delete(selectedMapboxDrawToolFeature.current.id)
    setMapboxDrawToolboxGeoJSONState(
      JSON.parse(JSON.stringify(map?.draw?.getAll())),
    )
    lineDrawEditPopupRef.current.remove()
    handleClose(type)
  }

  const clickEventSaver = useCallback(
    (e) => {
      clickEvent.current = e
    },
    [isMobile],
  )

  useEffect(() => {
    modeChanger(polygonEditPopupModeRef.current)
  }, [polygonEditPopupModeRef.current])

  useEffect(() => {
    map.on('draw.create', updateMapboxDrawFeatureRef.current)
    map.on('draw.update', updateMapboxDrawFeatureRef.current)
    map.on('draw.delete', updateMapboxDrawFeatureRef.current)
    isMobile &&
      map.on(
        'touchstart',
        [
          'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
          'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
        ],
        mapboxDrawFeaturesPointsClickStartHandlerRef.current,
      )
    map.on(
      isMobile ? 'touchend' : 'click',
      [
        'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
      ],
      mapboxDrawFeaturesPointsClickHandlerRef.current,
    )
    map.on('draw.selectionchange', selectionChangeHandlerRef.current)
    map.on(isMobile ? 'touchstart' : 'click', clickEventSaver)
    map.on(
      'mouseenter',
      [
        'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
      ],
      onVertexMouseEnterHandler,
    )
    map.on(
      'mouseout',
      [
        'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
        'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
      ],
      onVertexMouseOutHandler,
    )
    map.on(
      'closeMapboxGlDrawPopupsAndSelection',
      closeMapboxGlDrawPopupsAndSelection,
    )

    return () => {
      map.off('draw.create', updateMapboxDrawFeatureRef.current)
      map.off('draw.update', updateMapboxDrawFeatureRef.current)
      map.off('draw.delete', updateMapboxDrawFeatureRef.current)

      isMobile &&
        map.on(
          'touchstart',
          [
            'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
            'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
          ],
          mapboxDrawFeaturesPointsClickStartHandlerRef.current,
        )
      map.off(
        isMobile ? 'touchend' : 'click',
        [
          'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
          'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
        ],
        mapboxDrawFeaturesPointsClickHandlerRef.current,
      )

      map.off('draw.selectionchange', selectionChangeHandlerRef.current)
      map.off(isMobile ? 'touchstart' : 'click', clickEventSaver)
      map.off(
        'mouseenter',
        [
          'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
          'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
        ],
        onVertexMouseEnterHandler,
      )
      map.off(
        'mouseout',
        [
          'gl-draw-polygon-and-line-vertex-stroke-inactive.hot',
          'gl-draw-polygon-and-line-vertex-stroke-inactive.cold',
        ],
        onVertexMouseOutHandler,
      )
      map.off(
        'closeMapboxGlDrawPopupsAndSelection',
        closeMapboxGlDrawPopupsAndSelection,
      )
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      map.draw.changeMode(
        getDrawerToolboxClickedState ||
          getEraserClickedState ||
          getCommentToolboxState ||
          getTextToolboxState ||
          getRulerClickedState ||
          getStickyNotesClickedState
          ? 'static'
          : 'simple_select',
      )
    }, 10)

    if (
      getDrawerToolboxClickedState ||
      getTextToolboxState ||
      getEraserClickedState ||
      getCommentToolboxState ||
      getRulerClickedState ||
      getStickyNotesClickedState ||
      getTextToolboxState
    ) {
      map.fire('closeMapboxGlDrawPopupsAndSelection')
    }
  }, [
    getDrawerToolboxClickedState,
    getEraserClickedState,
    getCommentToolboxState,
    getRulerClickedState,
    getStickyNotesClickedState,
    getTextToolboxState,
  ])

  useEffect(() => {
    if (getPolygonDrawToolboxState || getLineDrawToolboxState) {
      if (lineDrawEditPopupRef.current && lineDrawEditPopupRef.current.isOpen())
        lineDrawEditPopupRef.current.remove()
      if (
        polygonDrawEditPopupRef.current &&
        polygonDrawEditPopupRef.current.isOpen()
      )
        polygonDrawEditPopupRef.current.remove()
    }
  }, [getPolygonDrawToolboxState, getLineDrawToolboxState])

  useEffect(() => {
    if (!map?.draw) {
      const mapboxDrawDefaultSimpleSelect = {
        ...MapboxDraw.modes.simple_select,
      }
      const mapboxDrawDefaultDirectSelect = {
        ...MapboxDraw.modes.direct_select,
      }
      const newModesForMapboxDraw = {
        ...MapboxDraw.modes,
        static: StaticMode,
        default_simple_select: mapboxDrawDefaultSimpleSelect,
        default_direct_select: mapboxDrawDefaultDirectSelect,
      }

      const mapboxDrawModes = MapboxDrawWaypoint.enable(newModesForMapboxDraw)

      const drawInstance = new MapboxDraw({
        defaultMode: 'simple_select',
        userProperties: true,
        styles: mapboxGlDrawStyles,
        modes: mapboxDrawModes,
        // clickBuffer: 4,
        // touchBuffer: 6,
        displayControlsDefault: false,
      })

      map.addControl(drawInstance)
      map.draw = drawInstance
    }

    if (!!isScreenshot && getIsAllToolboxStatesLoaded) {
      map.draw.deleteAll()
      map.draw.set(
        getMapboxDrawToolboxGeoJSONState?.features?.length
          ? JSON.parse(JSON.stringify(getMapboxDrawToolboxGeoJSONState))
          : {
              type: 'FeatureCollection',
              features: [],
            },
      )
    }
  }, [getIsAllToolboxStatesLoaded, isScreenshot])

  useEffect(() => {
    if (getPolygonDrawToolboxState) selectedToolType.current = 'Polygon'
    if (getLineDrawToolboxColors) selectedToolType.current = 'LineString'
  }, [getLineDrawToolboxColors, getPolygonDrawToolboxState])

  useEffect(() => {
    if (!lineDrawEditPopupRef.current) {
      lineDrawEditPopupRef.current = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
        maxWidth: 180,
        className: 'mapbox-popup-draw-line-container',
        anchor: 'bottom',
      })

      const lineContainer = document.createElement('div')
      lineContainer.className = 'draw-lines-popup-container'
      lineDrawEditPopupRef.current.setDOMContent(lineContainer)

      ReactDOM.render(
        <Provider store={store}>
          <LineDrawCreateEditPopup
            handleClose={handleClose}
            handleDelete={handleDelete}
            updateColorStatus={updateLineDrawColorStatus}
          />
        </Provider>,
        lineContainer,
      )

      lineDrawEditPopupRef.current.on('open', () => {
        if (!map.draw) return
        changeEditPopupPositionAsNeed()
        setLineDrawToolboxColorPickerState(false)
        // if (lineOrPolygonDrawHoverPopupRef.current && lineOrPolygonDrawHoverPopupRef.current.isOpen()) lineOrPolygonDrawHoverPopupRef.current.remove();

        const currentFeatureProperties =
          selectedMapboxDrawToolFeature?.current?.properties

        setLineDrawToolboxCurrentColor(
          currentFeatureProperties && currentFeatureProperties.portColor
            ? currentFeatureProperties.portColor
            : defaultStrokeColor,
        )
        setLineDrawToolboxWeight(
          currentFeatureProperties && currentFeatureProperties.portWidth
            ? currentFeatureProperties.portWidth
            : defaultWeight,
        )

        lineDrawEditPopupRef.current.setDOMContent(lineContainer)
      })

      lineDrawEditPopupRef.current.on('close', (e) => {
        setLineDrawToolboxColorPickerState(false)
      })

      !lineDrawEditPopupRef.current.isOpen() &&
        lineDrawEditPopupRef.current.addTo(map)

      if (!lineOrPolygonDrawHoverPopupRef.current) {
        lineOrPolygonDrawHoverPopupRef.current = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          className: 'mapbox-hover-popup-draw-line-container',
          dynamicPosition: true,
          anchor: 'bottom-right',
        })

        lineOrPolygonDrawHoverPopupRef.current.on('open', (e) => {
          if (
            isMobile &&
            lineDrawEditPopupRef.current &&
            lineDrawEditPopupRef.current.isOpen()
          )
            lineDrawEditPopupRef.current.remove()
        })
      }
    }

    if (!polygonDrawEditPopupRef.current) {
      polygonDrawEditPopupRef.current = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
        maxWidth: 130,
        maxHeight: 30,
        className: 'mapbox-popup-draw-polygon-container',
        anchor: 'bottom',
      })

      const polygonContainer = document.createElement('div')
      polygonContainer.className = 'draw-polygons-popup-container'
      polygonDrawEditPopupRef.current.setDOMContent(polygonContainer)

      ReactDOM.render(
        <Provider store={store}>
          <PolygonDrawCreateEditPopup
            handleClose={handleClose}
            handleDelete={handleDelete}
            modeChanger={modeChanger}
            updateColorStatus={updatePolygonDrawColorStatus}
          />
        </Provider>,
        polygonContainer,
      )

      polygonDrawEditPopupRef.current.on('open', () => {
        if (!map.draw) return
        setPolygonDrawToolboxColorPickerState(false)

        const currentFeatureProperties =
          selectedMapboxDrawToolFeature.current?.properties

        setPolygonDrawToolboxCurrentFillColor(
          currentFeatureProperties && currentFeatureProperties.portFillColor
            ? currentFeatureProperties.portFillColor
            : defaultFillColor,
        )
        setPolygonDrawToolboxCurrentFillOpacity(
          currentFeatureProperties && currentFeatureProperties.portFillOpacity
            ? currentFeatureProperties.portFillOpacity
            : defaultFillOpacity,
        )
        setPolygonDrawToolboxCurrentStrokeColor(
          currentFeatureProperties && currentFeatureProperties.portStrokeColor
            ? currentFeatureProperties.portStrokeColor
            : defaultStrokeColor,
        )
        setPolygonDrawToolboxCurrentStrokeOpacity(
          currentFeatureProperties && currentFeatureProperties.portStrokeOpacity
            ? currentFeatureProperties.portStrokeOpacity
            : defaultStrokeOpacity,
        )

        polygonDrawEditPopupRef.current.setDOMContent(polygonContainer)
      })

      polygonDrawEditPopupRef.current.on('close', (e) => {
        setPolygonDrawToolboxColorPickerState(false)
      })

      !polygonDrawEditPopupRef.current.isOpen() &&
        polygonDrawEditPopupRef.current.addTo(map)
    }
  }, [])

  useEffect(() => {
    if (
      polygonDrawEditPopupRef.current &&
      polygonDrawEditPopupRef.current.isOpen()
    ) {
      globalLoading && handleClose('polygon')
    }
    if (lineDrawEditPopupRef.current && lineDrawEditPopupRef.current.isOpen()) {
      globalLoading && handleClose('line')
    }
    lineDrawEditPopupRef.current.remove()
    polygonDrawEditPopupRef.current.remove()
  }, [globalLoading])

  // useEffect(() => {
  //     globalLoading && handleClose('polygon')
  // },[globalLoading])

  return (
    <>
      {hasPolygonDraw && (
        <PolygonDrawToolbox
          isScreenshot={isScreenshot}
          selectedMapboxDrawToolFeature={selectedMapboxDrawToolFeature.current}
          selectedToolType={selectedToolType.current}
          draw={map.draw}
        />
      )}
      {hasLineDraw && (
        <LineDrawToolbox
          isScreenshot={isScreenshot}
          selectedMapboxDrawToolFeature={selectedMapboxDrawToolFeature.current}
          selectedToolType={selectedToolType.current}
          draw={map.draw}
        />
      )}
    </>
  )
}

const mapStateTopProps = (state) => ({
  getStickyNotesClickedState: getStickyNotesClickedState(state),
  getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
  getRulerClickedState: getRulerClickedState(state),
  getEraserClickedState: getEraserClickedState(state),
  getCommentToolboxState: getCommentToolboxState(state),
  getTextToolboxState: getTextToolboxState(state),
  getPolygonDrawToolboxState: getPolygonDrawToolboxState(state),
  getLineDrawToolboxState: getLineDrawToolboxState(state),
  getLineDrawToolboxColors: getLineDrawToolboxColors(state),
  getLineDrawToolboxCurrentColor: getLineDrawToolboxCurrentColor(state),
  getLineDrawToolboxWeight: getLineDrawToolboxWeight(state),
  getMapboxDrawToolboxGeoJSONState: getMapboxDrawToolboxGeoJSONState(state),
  getPolygonDrawToolboxColors: getPolygonDrawToolboxColors(state),
  getPolygonDrawToolboxCurrentFillColor:
    getPolygonDrawToolboxCurrentFillColor(state),
  getPolygonDrawToolboxCurrentStrokeColor:
    getPolygonDrawToolboxCurrentStrokeColor(state),
  getPolygonDrawToolboxCurrentFillOpacity:
    getPolygonDrawToolboxCurrentFillOpacity(state),
  getPolygonDrawToolboxCurrentStrokeOpacity:
    getPolygonDrawToolboxCurrentStrokeOpacity(state),
  getIsAllToolboxStatesLoaded: getIsAllToolboxStatesLoaded(state),
})

const mapDispatchToProps = {
  setLineDrawToolboxColors: setLineDrawToolboxColors,
  setLineDrawToolboxState: setLineDrawToolboxState,
  setLineDrawToolboxCurrentColor: setLineDrawToolboxCurrentColor,
  setLineDrawToolboxWeight: setLineDrawToolboxWeight,
  setLineDrawToolboxColorPickerState: setLineDrawToolboxColorPickerState,
  setMapboxDrawToolboxGeoJSONState: setMapboxDrawToolboxGeoJSONState,
  setPolygonDrawToolboxState: setPolygonDrawToolboxState,
  setPolygonDrawToolboxColors: setPolygonDrawToolboxColors,
  setPolygonDrawToolboxCurrentStrokeColor:
    setPolygonDrawToolboxCurrentStrokeColor,
  setPolygonDrawToolboxCurrentStrokeOpacity:
    setPolygonDrawToolboxCurrentStrokeOpacity,
  setPolygonDrawToolboxCurrentFillColor: setPolygonDrawToolboxCurrentFillColor,
  setPolygonDrawToolboxCurrentFillOpacity:
    setPolygonDrawToolboxCurrentFillOpacity,
  setPolygonDrawToolboxColorPickerState: setPolygonDrawToolboxColorPickerState,
}
export default connect(
  mapStateTopProps,
  mapDispatchToProps,
)(memo(MapboxDrawToolbox))
