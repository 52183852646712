import React, {memo, useRef, useState} from 'react';
import {connect} from "react-redux";

import ColorCircle from "../components/ColorCircle";

import {dispatchUndoRedoData, setPainterGeoJsonDataST} from "../../../store/actions/mapStateAction";

import {SIZE_DATA} from "../../../utils/color_picker_data";
import {color_picker_data} from "./constants";

const DrawerPopup = (props) => {
    const {
        updateSizeFromPopup,
        drawerColor,
        colorsData,
        setColorsData
    } = props;

    const [drawerSizeData, setDrawerSizeData] = useState(SIZE_DATA);
    const [getColorPickerShowState, setColorPickerShowState] = useState(false);


    const updateColorStatus = (id) => {
        setColorsData(colorsData.map((item) => {
            item.status = 0;
            if (item.id === id) {
                item.status = 1;
            }
            return item;
        }))
    };

    const updateSizeIcon = (id) => {
        setDrawerSizeData(
            drawerSizeData.map((item) => {
                item.status = 0;
                if (item.id === id) {
                    item.status = 1;
                }

                return item;
            })
        );
    };

    const selectColorFromColorPickerPopup = (color) => {
        drawerColor.current = color;
        setColorPickerShowState(false);
    }

    const openColorPickerPopup = (e, item, i) => {
        e.preventDefault();
        e.stopPropagation();
        if (!item?.img) {
            drawerColor.current = item?.color;
        }
        setColorPickerShowState(false);
        if (i === 5) {
            setColorPickerShowState(!getColorPickerShowState);
        }
    };

    return (
            <div className="painter_tools_container">
                <div className="painter_tools_size">
                    {drawerSizeData.map((item) => (
                        <div
                            key={item.id}
                            style={{
                                backgroundColor: item?.status ? "#2c476c" : "",
                            }}
                            className="painter_tools_size_icon_block"
                            onClick={(e) => {
                                updateSizeFromPopup(e, item)
                                updateSizeIcon(item?.id);
                            }}
                        >
                            <div
                                className="painter_tools_size_icon"
                                style={{
                                    backgroundImage: `url(${
                                        item.status ? item?.active : item?.passive
                                    })`,
                                }}
                            ></div>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        width: "100%",
                        height: "1px",
                        background: "#BFBFC1",
                    }}
                ></div>
                <div className="painter_tools_color">
                    {colorsData.map((item, i) => (
                        <div
                            key={i}
                            className="painter_tools_color_data_item"
                            onClick={(e) => {
                                openColorPickerPopup(e, item, i)
                                updateColorStatus(item.id);
                            }}
                        >
                            {i === colorsData.length - 1 ? (
                                <div className={"color_circle"}>
                                    <svg
                                        style={{
                                            background: "conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                            borderRadius: "50%",
                                            height: 25,
                                            width: 25,
                                            stroke: item.status ? "#fff" : "#0000",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                    >
                                        <circle cx="12.5" cy="12.5" r="10" fill="#0000"></circle>
                                    </svg>
                                </div>
                            ) : (
                                <div className={"color_circle"}>
                                    <ColorCircle color={item} status={item.status}
                                                 isPaint={true}
                                                 updateColorStatus={updateColorStatus}/>
                                </div>
                            )}
                        </div>
                    ))}
                    {getColorPickerShowState && (
                        <div
                            className="painter_tools_color_item_container"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {color_picker_data.map((color, i) => (
                                <div
                                    key={i}
                                    className="painter_tools_color_item"
                                    style={{
                                        backgroundColor: color,
                                    }}
                                    onClick={() => {
                                        selectColorFromColorPickerPopup(color)
                                    }}
                                ></div>
                            ))}
                            <span/>
                        </div>
                    )}
                </div>
            </div>
    );
};

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    setPainterGeoJsonDataST: setPainterGeoJsonDataST,
    dispatchUndoRedoData: dispatchUndoRedoData,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DrawerPopup));
