import React, {memo, useEffect, useRef} from 'react';
import {connect} from "react-redux";

import {
    getPainterGeoJsonDataST,
    getRulerGeoJsonDataST,
    getSelectedToolUndoRedo,
    getUndoRedoData,
} from "../../../../store/selectors";
import {
    setPainterGeoJsonDataST,
    setRulerGeoJsonDataST, setSelectedToolUndoRedo, setUndoRedoData
} from "../../../../store/actions/mapStateAction";

import {DRAWER_TOOLBOX_LAYER, RULER_LAYER_AND_SOURCE_NAME} from "../../DrawerToolbox/constants";
import PrevIcon from "../../../../assets/imgs/PaintBar/prev-icon.png";
import {MAP_TOOLBOX_KEYS} from "../../../mapToolbox";

const UndoToolbox = (props) => {
    const {
        getSelectedToolUndoRedo,
        getUndoRedoData,
        setPainterGeoJsonDataST,
        setRulerGeoJsonDataST,
        setUndoRedoData,
        map
    } = props;

    const currentUndoRedoSelectedComponent = useRef(null);

    useEffect(() => {
        if(getUndoRedoData && getSelectedToolUndoRedo) {
            currentUndoRedoSelectedComponent.current = getUndoRedoData[getSelectedToolUndoRedo];
        }
    }, [getSelectedToolUndoRedo, getUndoRedoData]);

    const getDrawingSource = () => {
        return map.getSource(DRAWER_TOOLBOX_LAYER);
    };

    const getRulerSource = () => {
        return map.getSource(RULER_LAYER_AND_SOURCE_NAME);
    };

    const handleUndo = () => {
        currentUndoRedoSelectedComponent.current = getUndoRedoData[getSelectedToolUndoRedo];
        if (currentUndoRedoSelectedComponent.current.index <= 0) return; // Prevent undoing beyond the stored history

        const newValue = currentUndoRedoSelectedComponent.current.history[currentUndoRedoSelectedComponent.current.index - 1];

        setUndoRedoData({
            ...getUndoRedoData,
            [getSelectedToolUndoRedo]: {
                ...currentUndoRedoSelectedComponent.current,
                index: currentUndoRedoSelectedComponent.current.index - 1,
            },
        });

        switch (getSelectedToolUndoRedo) {
            case MAP_TOOLBOX_KEYS.DRAWER:
                setPainterGeoJsonDataST(JSON.parse(JSON.stringify(newValue)));
                getDrawingSource().setData(JSON.parse(JSON.stringify(newValue)));
                break;
            case MAP_TOOLBOX_KEYS.RULER:
                setRulerGeoJsonDataST(JSON.parse(JSON.stringify(newValue)));
                getRulerSource().setData(JSON.parse(JSON.stringify(newValue)));
                break;
            default:
                break;
        }
    };


    return (
        <div className="pain_items" onClick={handleUndo}>
            <img src={PrevIcon} alt="" className="icon_img" />
        </div>
    );
};

const mapStateToProps = (state) => ({
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getRulerGeoJsonDataST: getRulerGeoJsonDataST(state),
    getSelectedToolUndoRedo: getSelectedToolUndoRedo(state),
    getUndoRedoData: getUndoRedoData(state),
})
const mapDispatchToProps = {
    setPainterGeoJsonDataST: setPainterGeoJsonDataST,
    setRulerGeoJsonDataST: setRulerGeoJsonDataST,
    setSelectedToolUndoRedo: setSelectedToolUndoRedo,
    setUndoRedoData: setUndoRedoData,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(UndoToolbox));