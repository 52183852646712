import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {isMobile} from "react-device-detect";
import { v4 as uuidv4 } from 'uuid';

import DrawerPopup from "./DrawerPopup";
import CustomCursor from "../components/CustomCursor";
import CustomPortal from "../components/CustomCursor/CustomPortal";


import {
    getIsAllToolboxStatesLoaded,
    getPainterStartData,
    getRulerClickedState,
    getDrawerToolboxClickedState,
    getPainterGeoJsonDataST,
    getMapStyledId,
} from "../../../store/selectors";
import {
    setCommentToolboxState,
    setDrawerToolboxClickedState,
    setEraserClickedState,
    setLineDrawToolboxState, setPolygonDrawToolboxState,
    setRulerClickedState,
    setStickyNotesClickedState,
    setTextToolboxState
} from "../../../store/actions/painterStart";

import {
    dispatchUndoRedoData,
    setPainterGeoJsonDataST,
} from "../../../store/actions/mapStateAction";

import {changeCursorIcon, CURSOR_TYPE} from "../../../shared/mockData";
import {
    DRAWER_TOOLBOX_LAYER, ERASER_TOOLBOX_LAYER,
    MINIMAL_COORDINATES_LENGTH_COUNT
} from "./constants";

import './style.css';
import BrashIcon from "../../../assets/imgs/PaintBar/brush.png";
import {mapboxGlDrawStyles} from "../MapboxDrawToolbox/constants";


const DrawerToolbox = (props) => {
    const {
        getPainterStartData,
        getDrawerToolboxClickedState,
        setDrawerToolboxClickedState,
        getPainterGeoJsonDataST,
        setPainterGeoJsonDataST,
        getIsAllToolboxStatesLoaded,
        setCommentToolboxState,
        setLineDrawToolboxState,
        setPolygonDrawToolboxState,
        setRulerClickedState,
        setStickyNotesClickedState,
        setEraserClickedState,
        hasLineOrPolygonDraw,
        setTextToolboxState,
        dispatchUndoRedoData,
        getMapStyledId,
        isScreenShot,
        // drawerGeoJsonRef,
        map,
    } = props;

    const [colorsData, setColorsData] = useState([
        {id: 1, color: "#000000", status: 1},
        {id: 2, color: "#157EFA", status: 0},
        {id: 3, color: "#53D669", status: 0},
        {id: 4, color: "#FED031", status: 0},
        {id: 5, color: "#FC3142", status: 0},
        {id: 6, status: 0},
    ]);
    const [isVisibleCursor, setIsVisibleCursor] = useState(false);

    const drawerSize = useRef(8);
    const drawerColor = useRef('#000'); // Default color
    const drawerGeoJsonRef = useRef(null);
    const currentDrawingLineRef = useRef(null);

    const mouseDownDrawerHandlerRef = useRef(null);
    const mouseUpDrawerHandlerRef = useRef(null);
    const mouseMoveDrawerHandlerRef = useRef(null);

    const createSourceAndLayers = () => {
        if (!map.getSource(DRAWER_TOOLBOX_LAYER)) {
            map.addSource(DRAWER_TOOLBOX_LAYER, {
                'type': 'geojson',
                'data': drawerGeoJsonRef.current
            });
        }

        if (!map.getLayer(DRAWER_TOOLBOX_LAYER)) {
            map.addLayer({
                id: DRAWER_TOOLBOX_LAYER,
                type: 'line',
                source: DRAWER_TOOLBOX_LAYER,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round'
                },
                paint: {
                    'line-color': ['get', 'color'],
                    'line-width': ['get', 'width']
                }
            });
        }

        if (!map.getSource(ERASER_TOOLBOX_LAYER)) {
            map.addSource(ERASER_TOOLBOX_LAYER, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                }
            });
        }

        if (!map.getLayer(ERASER_TOOLBOX_LAYER)) {
            map.addLayer({
                id: ERASER_TOOLBOX_LAYER,
                type: 'fill',
                source: ERASER_TOOLBOX_LAYER,
                paint: {
                    'fill-color': '#0000ff',
                    'fill-opacity': 0.5
                }
            });
        }

        // MOVE MAPBOX-DRAW LIBRARY CREATED LAYERS TO TOP OF THE Z-INDEX IF THEY EXIST
        if(hasLineOrPolygonDraw) {
            mapboxGlDrawStyles.forEach(layer => {
                if (map.getLayer(`${layer.id + '.hot'}`) || map.getLayer(`${layer.id + '.cold'}`)) {
                    map.moveLayer(`${layer.id + '.hot'}`);
                    map.moveLayer(`${layer.id + '.cold'}`);
                }
            });
        }
    };
    const getDrawingSource = () => {
        return map.getSource(DRAWER_TOOLBOX_LAYER);
    };
    useEffect(() => {
        setTimeout(() => {
            createSourceAndLayers();
            if (Object.keys(getPainterGeoJsonDataST).length > 0) {
                drawerGeoJsonRef.current = JSON.parse(JSON.stringify(getPainterGeoJsonDataST));
            }
            getDrawingSource().setData(drawerGeoJsonRef.current);
        }, 2000)
    }, [getMapStyledId])

    const handleIconClick = () => {
        setStickyNotesClickedState(false);
        setEraserClickedState(false);
        setLineDrawToolboxState(false);
        setPolygonDrawToolboxState(false);
        setCommentToolboxState(false);
        setTextToolboxState(false);
        setRulerClickedState(false);
        setDrawerToolboxClickedState(!getDrawerToolboxClickedState);
    };

    const documentOverHandler = useCallback(() => {
        function removeListenerFromDocument(){
            document.removeEventListener('mouseup', offMouseOverFunctionOfMap);
        }
        function offMouseOverFunctionOfMap () {
            map.off('mouseover', removeListenerFromDocument);
            mouseUpDrawerHandlerRef.current();
        }
        document.addEventListener('mouseup', offMouseOverFunctionOfMap,{ once: true });
        map.once('mouseover', removeListenerFromDocument);
    }, []);

    const mouseMoveDrawerHandler = (e) => {
        // const renderedDrawerFeatures = map.queryRenderedFeatures(e.point, {
        //     layers: [DRAWER_TOOLBOX_LAYER],
        // });
        //
        // if (renderedDrawerFeatures.length > 0) {
        //     return;
        // }
        if (!currentDrawingLineRef.current) return;
        const coords = e.lngLat.toArray();
        currentDrawingLineRef.current.geometry.coordinates.push(coords);
        if (currentDrawingLineRef.current.geometry.coordinates.length < 2) return;
        const source = getDrawingSource();
        source.setData(source._data);
    };

    const mouseDownDrawerHandler = (e) => {
        const id = uuidv4();
        currentDrawingLineRef.current = {
            id: id,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: []
            },
            properties: {
                id: id,
                color: drawerColor.current,
                width: drawerSize.current,
            }
        };

        const source = getDrawingSource();
        const data = source._data;
        data.features.push(currentDrawingLineRef.current);
        source.setData(data);
        setIsVisibleCursor(true);

        map.on('mouseout', documentOverHandler);
        map.on(isMobile ? 'touchmove' : 'mousemove', mouseMoveDrawerHandlerRef.current);
    };

    const mouseUpDrawerHandler = () => {
        currentDrawingLineRef.current = null;
        setIsVisibleCursor(false);

        const source = getDrawingSource();
        const data = source._data;

        if (data.features.length && data.features[data.features.length - 1].geometry.coordinates.length <= MINIMAL_COORDINATES_LENGTH_COUNT) {
            data.features.pop();
            source.setData(data);
            setPainterGeoJsonDataST({...getDrawingSource()._data});
            return;
        }

        map.off('mouseout', documentOverHandler);
        map.off(isMobile ? 'touchmove' : 'mousemove', mouseMoveDrawerHandlerRef.current);

        setPainterGeoJsonDataST({...getDrawingSource()._data});
        dispatchUndoRedoData({...JSON.parse(JSON.stringify(getDrawingSource()._data))});
    };

    const updateSizeFromPopup = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        drawerSize.current = item.value;
    }

    useEffect(() => {
        if (getPainterStartData && getDrawerToolboxClickedState) {
            setTimeout(() => {
                changeCursorIcon(CURSOR_TYPE.DRAW)
                map["dragPan"].disable();
            }, 50);
            map.on(isMobile ? 'touchstart' : 'mousedown', mouseDownDrawerHandlerRef.current);
            map.on(isMobile ? 'touchend' : 'mouseup', mouseUpDrawerHandlerRef.current);
        } else {
            changeCursorIcon();
            map["dragPan"].enable();
            map.off(isMobile ? 'touchstart' : 'mousedown', mouseDownDrawerHandlerRef.current);
            map.off(isMobile ? 'touchend' : 'mouseup', mouseUpDrawerHandlerRef.current);
            setDrawerToolboxClickedState(false);
        }
    }, [getPainterStartData, getDrawerToolboxClickedState]);

    useEffect(() => {
        mouseDownDrawerHandlerRef.current = mouseDownDrawerHandler;
        mouseUpDrawerHandlerRef.current = mouseUpDrawerHandler;
        mouseMoveDrawerHandlerRef.current = mouseMoveDrawerHandler;
        drawerGeoJsonRef.current = {
            type: 'FeatureCollection',
            features: []
        };

        // createSourceAndLayers();
    }, []);


    useEffect(() => {
        if (!isScreenShot) return;
        if (!getIsAllToolboxStatesLoaded) return;
        if (Object.keys(getPainterGeoJsonDataST).length > 0) {
            drawerGeoJsonRef.current = JSON.parse(JSON.stringify(getPainterGeoJsonDataST));
            if (getDrawingSource()) {
                getDrawingSource().setData(drawerGeoJsonRef.current);
            }
        } else {
            if (getDrawingSource()) {
                getDrawingSource().setData(drawerGeoJsonRef.current);
            }
        }

    }, [isScreenShot, getIsAllToolboxStatesLoaded]);

    return (
        <div className={`pain_items ${getDrawerToolboxClickedState ? "button_active" : ""}`}>
            <div style={{width: 36, height: 36, display: "flex", justifyContent: "center", alignItems: "center"}}
                 onClick={handleIconClick}>
                <img src={BrashIcon} alt="" className="icon_img"/>
            </div>
            {getPainterStartData && getDrawerToolboxClickedState && <DrawerPopup colorsData={colorsData} setColorsData={setColorsData} drawerColor={drawerColor} updateSizeFromPopup={updateSizeFromPopup} />}
            {isMobile && getDrawerToolboxClickedState && getPainterStartData && isVisibleCursor &&
                <CustomPortal cssText={'z-index: 0; position:fixed; top:0; left:0; width:100vw; height:100vh; pointer-events:none;'}>
                    <CustomCursor type={'drawer-cursor'}/>
                </CustomPortal>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    getRulerClickedState: getRulerClickedState(state),
    getPainterStartData: getPainterStartData(state),
    getIsAllToolboxStatesLoaded: getIsAllToolboxStatesLoaded(state),
    getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getMapStyledId: getMapStyledId(state),
})
const mapDispatchToProps = {
    setCommentToolboxState: setCommentToolboxState,
    setTextToolboxState: setTextToolboxState,
    setLineDrawToolboxState: setLineDrawToolboxState,
    setDrawerToolboxClickedState: setDrawerToolboxClickedState,
    setStickyNotesClickedState: setStickyNotesClickedState,
    setRulerClickedState: setRulerClickedState,
    setEraserClickedState: setEraserClickedState,
    setPainterGeoJsonDataST: setPainterGeoJsonDataST,
    setPolygonDrawToolboxState: setPolygonDrawToolboxState,
    dispatchUndoRedoData: dispatchUndoRedoData,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(DrawerToolbox));
