import {
  SET_LAMP,
  LOADING_LAMP,
  SET_SORTED_TOOLTIP_SELECT_ITAM,
  SET_LAMP_MODAL_STATE,
  SET_LAMP_MODAL_DATA, SET_LAMP_MODAL_STATE_INDEX, SET_READ_MORE_MODAL_STATE,
} from "../constants";

export const loadingLamp = () => ({
  type: LOADING_LAMP,
});

export const setLamp = (articles) => {
  return {
    type: SET_LAMP,
    payload: articles,
  };
};

export const setSortedTooltipSelectItem = (articles) => ({
  type: SET_SORTED_TOOLTIP_SELECT_ITAM,
  payload: articles,
});

export const setLampModalState = (state) => {
  return {
    type: SET_LAMP_MODAL_STATE,
    payload: state,
  };
};

export const setLampModalStateIndex = (state) => {
  return {
    type: SET_LAMP_MODAL_STATE_INDEX,
    payload: state,
  };
};

export const setLampModalData = (articles) => ({
  type: SET_LAMP_MODAL_DATA,
  payload: articles,
});

const parseLampData = (data, type) => {
  const lamp = [];
  if (type === "lesson") {
    let articles = data.articles;
    articles.forEach((article) => {
      const hasMainCat = article?.subcategory.findIndex(el=>el.isMain) !== -1
      if (article.isLamp && hasMainCat) {
        lamp.push(article);
      }
    });
  }
  if (type === "topic") {
    let lesson = data.lessons;
    lesson.forEach((item) => {
      item.articles.forEach((article) => {
        const hasMainCat = article?.subcategory.findIndex(el=>el.isMain) !== -1
        if (article.isLamp && hasMainCat) {
          lamp.push(article);
        }
      });
    });
  }
  if (type === "article") {
    const hasMainCat = data?.subcategory.findIndex(el=>el.isMain) !== -1
    if (data.isLamp && hasMainCat) {
      lamp.push(data);
    }
  }
  return lamp;
};

export const getLampInfo = (data, type) => (dispatch) => {
  dispatch(loadingLamp());
  const lampData = parseLampData(data, type);
  const uniqueLampData = [
    ...new Map(lampData.map((item) => {
      item.show = true
      return [item.id, item]
    })).values(),
  ];
  dispatch(setLamp(uniqueLampData));
};
