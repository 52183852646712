import {formatArticleTime, formatLessonTime, formatServerResponseTime} from '../utils'
import axios from "axios";
import {setCompassNewAngle, setCompassRotate} from "../../../store/actions/compassStateAction";
import {loadingArticles, setArticles, setSelectedArticle} from "../../../store/actions/articles";
import {getArticleAPI} from "../../../service/article";

export const processTimeLineTopicItemsRendering = async (data,type,getState,dispatch) => {
    function convertToFourDigitYear(year,manth,day) {
        var isNegative = year < 0;
        var absoluteYear = Math.abs(year);
        var paddedYear = absoluteYear.toString().padStart(4, '0');
        var date = new Date(`0001-${manth ? manth : '01'}-${day ? day : '01'}`);
        date.setFullYear(paddedYear);
        if (isNegative) {
            // Convert negative year to BCE
            date.setFullYear(-paddedYear);
        }
        return date;
    }

    function getArticleApi (id,getState,dispatch,stopFly) {
        dispatch(loadingArticles());
        getArticleAPI(id).then((article) => {
            let articles = getState().articles.data;
            const openedArticles = articles.filter((x) => x.show);
            if (
                articles.find((x) => x.id == id) !== undefined ||
                typeof articles.find((x) => x.id == id) !== "undefined"
            ) {
                const indexArticle = articles.findIndex((x) => x.id === article.id);
                articles[indexArticle].show = true;
            } else {
                if (openedArticles.length > 0) {
                    openedArticles.forEach((article) => {
                        const indexArticle = articles.findIndex((x) => x.id === article.id);
                        articles.splice(indexArticle, 1);
                    });
                }
                articles.push(article);
            }

            dispatch(
                setSelectedArticle({
                    id: id,
                    type: article.articleData.type,
                    fly: !!stopFly
                })
            );
            // dispatch(setSearch(false))
            dispatch(setArticles(articles));
        });
    }
    function calcByMonth(fullYear,fullMonth){
        let yearStart = fullYear
        let monthStart = fullMonth - 3

        let yearEnd = fullYear
        let monthEnd = fullMonth + 3

        if(monthStart <= 0){
            monthStart = 12 + monthStart
            yearStart -= 1
        }
        if(monthEnd > 12){
            monthEnd = 0 + (monthEnd - 12)
            yearEnd += 1
        }
        return {
            start:[yearStart, monthStart, 15],
            end:[yearEnd, monthEnd, 15]
        }

    }
    const timeLineDataItem = new Array()
    timeLineDataItem.__proto__.getItemByDateRange = function (step,time) {
        const fullYear = time.getFullYear()
        const fullMonth = time.getMonth()
        const rangeStart = {
                1000:[fullYear+250, 0, 0],
                500:[fullYear+150, 0, 0],
                100:[fullYear+5, 0, 0],
                50:[fullYear+2, 0, 0],
                10:[fullYear+1, 0, 0],
                5:[fullYear-1, '06', 0],
                1:calcByMonth(fullYear,fullMonth).start,
            }
        const rangeEnd = {
            1000:[fullYear-250, 0, 0],
            500:[fullYear-150, 0, 0],
            100:[fullYear-5, 0, 0],
            50:[fullYear-2, 0, 0],
            10:[fullYear-1, 0, 0],
            5:[fullYear+1, '06', 0],
            1:calcByMonth(fullYear,fullMonth).end
        }
        const start =  convertToFourDigitYear(...rangeStart[step]);
        const end =  convertToFourDigitYear(...rangeEnd[step]);
        return this.filter((item)=>{
            return (
                item.className === "related" &&
                !item.inGroup &&
                (new Date(item.start) >= start && new Date(item.start) <= end ||
                new Date(item.end) >= start && new Date(item.end) <= end ||
                new Date(item.start) <= start && new Date(item.end) >= end)
            );
        })
    }

    const { startFrom, endTo } = formatServerResponseTime(data)
    timeLineDataItem.push({
        id: `c-${data.id}`,
        order: 1,
        content: data.language?.[0].title,
        start: startFrom,
        end: endTo,
        className: 'isGroup',
        color: '#2c476c',
        startFrom: startFrom,
        endTo: endTo,
    })
    // data.lessons.forEach((lesson, index) => {
    //     lesson?.articles?.map(article=>{
    //         const { articleStartFrom, articleEndTo } = formatArticleTime(article)
    //         timeLineDataItem.push({
    //             id: `${data.id}-${lesson.id}-${article.id}`,
    //             order: index + 2,
    //             // content: article.content[0].mainTitle,
    //             // prevLessonID: index === 0 ? null : data.lessons[index - 1].id,
    //             start: articleStartFrom,
    //             end:articleStartFrom,
    //             color: '#2c476c',
    //             groupId: data.id,
    //             className:'related',
    //         })
    //     })
    // })
    await axios
        .get(
            `${process.env.REACT_APP_GIS_URL}/Metadata/TimelineMarkers?${type}Id=${data.id}`
        )
        .then((res) => {
            if (res.status === 200) {
                res?.data?.map((article,index)=>{
                    article.items.map((el,idx)=>{
                        const { articleStartFrom, articleEndTo } = formatArticleTime(article)
                        timeLineDataItem.push({
                            id: `${data.id}-${index}-${idx}`,
                            order: index * idx + 2,
                            start:convertToFourDigitYear((article.isBc ? '-' : '') + article.year),
                            end:convertToFourDigitYear((article.isBc ? '-' : '') + article.year),
                            color: '#2c476c',
                            groupId: `c-${data.id}`,
                            className:`related`,
                            elementType:el.type,
                            articleId:el?.articleId,
                            openMiddleTimeline:article.items.length > 1,
                            bounds:article?.bounds,
                            getArticle: (id) => {
                                getArticleApi(id, getState, dispatch)
                                dispatch(setCompassNewAngle(0));
                                dispatch(setCompassRotate(0));
                            }
                        })
                    })
                })
            }
        })
    return timeLineDataItem
}
