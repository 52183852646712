const MarkerIcon = ({ color, onClick }) => {
    function hexToRgb(hex) {
        let r = 0, g = 0, b = 0;
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }
        return { r, g, b };
    }
    const rgbToHex = ({ r, g, b }) => {
        const toHex = (c) => {
            const hex = c.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };
    const getSecondColor = (firstColorHex) => {
        const firstColorRgb = hexToRgb(firstColorHex);
        const difference = {
            r: -6,
            g: -40,
            b: -75
        };
        const secondColorRgb = {
            r: Math.max(0, Math.min(255, firstColorRgb.r + difference.r)),
            g: Math.max(0, Math.min(255, firstColorRgb.g + difference.g)),
            b: Math.max(0, Math.min(255, firstColorRgb.b + difference.b))
        };

        return rgbToHex(secondColorRgb);
    };
    const secColor = getSecondColor(color);
    return (
        <div onClick={onClick} style={{cursor: 'pointer', width: 40}}>
            <svg width="35" height="35" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1043_2843)">
                    <path
                        d="M18.4479 38.9694L14.0314 34.5528C13.8055 34.3214 13.4879 34.2032 13.1657 34.2304C12.8456 34.2535 12.5514 34.4147 12.3596 34.6721L0.220925 51.234C-0.14499 51.7218 -0.0461358 52.4139 0.441715 52.7797C0.834336 53.0741 1.37405 53.0741 1.76667 52.7797L18.3286 40.6344C18.8204 40.2741 18.9272 39.5831 18.5668 39.0912C18.5334 39.0457 18.4967 39.0027 18.4567 38.9628L18.4479 38.9694Z"
                        fill="#B6B1B1"
                    />
                    <path
                        d="M32.8018 31.2405L21.7607 20.1993C19.9031 18.7532 17.5888 18.0206 15.2374 18.1346C12.1594 18.0269 9.16896 19.17 6.9477 21.3034C5.15457 23.0965 5.34006 25.8679 7.49973 29.5424C9.53933 32.7405 11.9482 35.6874 14.6765 38.3224C19.0687 42.7124 24.6136 47.2746 28.6547 47.2746C29.7937 47.3035 30.8939 46.8608 31.6955 46.0512C36.2931 41.4559 35.147 33.588 32.8018 31.2405Z"
                        fill={secColor}
                    />
                    <path
                        d="M41.6355 22.4075L30.5942 11.3662C30.1631 10.9352 29.4642 10.9352 29.0329 11.3662L20.2 20.1992C18.7558 21.6434 18.875 24.8365 23.5123 29.4893C26.5399 32.5168 28.9534 33.6187 30.6671 33.6187C31.4602 33.6467 32.2306 33.3519 32.8024 32.8017L41.6354 23.9687C42.0665 23.5376 42.0665 22.8387 41.6355 22.4075Z"
                        fill={color}
                    />
                    <path
                        d="M52.9153 19.4544C52.7447 19.0415 52.3419 18.7722 51.8951 18.7721C49.5014 18.7721 44.2457 16.184 40.5315 12.4698C36.8173 8.75555 34.2292 3.50201 34.2292 1.10613C34.2302 0.496341 33.7368 0.00114034 33.127 1.71329e-06C32.833 -0.000515846 32.551 0.116245 32.3433 0.324408C27.834 4.83587 26.4252 12.5294 33.4475 19.5516C36.119 22.5204 39.8794 24.2803 43.8704 24.4296C47.19 24.3915 50.358 23.0337 52.6747 20.6557C52.9901 20.3407 53.085 19.8668 52.9153 19.4544Z"
                        fill={color}
                    />
                    <path
                        d="M46.0527 6.94915C41.3756 2.27424 37.1469 0.00195313 33.1256 0.00195313C32.5159 0.00195313 32.0215 0.496325 32.0215 1.10611C32.0215 4.40307 35.1396 10.2019 38.9687 14.0332C42.7978 17.8645 48.6011 20.9804 51.8957 20.9804C52.5055 20.9804 52.9999 20.486 52.9999 19.8762C52.9999 15.8528 50.7276 11.6239 46.0527 6.94915Z"
                        fill={secColor}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1043_2843">
                        <rect width="53" height="53" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default MarkerIcon;
