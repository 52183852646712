import React, { Component } from "react";
import "./style.css";
import About from "../about";
import { Link } from "react-router-dom";
import {withTranslation} from "react-i18next";
import {LanguageChanger} from "../../components/languageChanger";

class Account extends Component {
  constructor() {
    super();
    this.state = {
      about: false,
    };
  }

  showPopup = () => {
    this.setState({about: !this.state.about})
  }
  render() {
    const { onlyBG, children, t } = this.props;
    const { about } = this.state;
    const { showPopup } = this;

    return (
      <>
        <div className="account">
          <div
            className="account"
            style={{
              zIndex: -1,
            }}
          ></div>
          {!onlyBG && (
            <header className="account-header">
              <div className="account-header-first-block">
                <button
                  className="account-about"
                  type="button"
                  onClick={showPopup}
                >
                  {t('aboutUs')}
                </button>
                {/*<LanguageChanger />*/}
              </div>
              <div>
                <h1 className="account-title">{t('name')}</h1>՝
              </div>
            </header>
          )}
          {children}
          {!onlyBG && (
            <footer className="account-footer">
              <p className="account-powered">powered by brainograph</p>
            </footer>
          )}
        </div>
        {about && <About handleAbout={showPopup} />}
      </>
    );
  }
}

export default withTranslation()(Account);
