import { connect } from "react-redux";
import "./style.css";
import CardBody from "../../CustomCardComponents/CardBody";
import CloseBtn from "../../CustomCardComponents/CloseBtn";
import {useState} from "react";
import CustomSwitch from "../../CustomSwitch";
import DeleteSlideIcon from '../../../../assets/imgs/icons/delete-gray.svg'
import EditeSlideIcon from '../../../../assets/imgs/icons/edit-gray.svg'
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {setSlidesItems} from "../../../../store/actions/slides";
const cardBodyStyle = {
    width: "100%",
    minWidth: "300px",
    maxWidth: "400px", // Adjust this according to your design
    height: "100%",
    maxHeight: "190px",
    gridTemplateRows: "1fr auto",
    boxSizing: 'border-box',
    padding: "20px",
    backgroundColor: '#fff',
};
const SliderSettings = (props) => {
    const { onClose, handleEdit, handleDelete,item,handleEditSettings } = props;
    const [showDescriptions, setShowDescriptions] = useState(item?.showDescriptionPopup)
    const [isVoiceEnabled, setIsVoiceEnabled] = useState(item?.isVoiceEnabled)

    const sx= {
        color: '#D9D9D9',
        '&.Mui-checked': {
            color: '#24B263',
        },
    }

    const handleShowDescriptions = () => {
        setShowDescriptions(prev=>!prev)
        const body = {
            showDescriptionPopup:!showDescriptions,
        }
        handleEditSettings(body)
    }
    const handleVoice = () => {
        // setIsVoiceEnabled(prev=>!prev)
        //
        // const body = {
        //     isVoiceEnabled:!isVoiceEnabled,
        // }
        // handleEditSettings(body)
    }
    const handleChangeSpeaker = (e) => {
        const body = {
            audioGender:e?.target?.value || 'Male',
        }
        handleEditSettings(body)
    }
    const Switcher = ({ text, action, value }) => {
        return (
            <div className='switcher'>
                <p>{text}</p>
                <CustomSwitch
                    color='secondary'
                    checked={value}
                    onChange={action}
                />
            </div>
        )
    }

    const EditAndDelete = ({type, action}) => {
        return(
            <div className='edit__and__delete'>
                <p>{type === 'delete' ? 'Ջնջել ցուցադրությունը' : 'Խմբագրել ցուցադրությունը'}</p>
                <button onClick={action}>
                    <img className='button__icon' src={type === 'delete' ? DeleteSlideIcon : EditeSlideIcon}/>
                </button>
            </div>
        )
    }

    return (
        <div className="slide-settings-content">
            <CardBody classN='slider__settings' hasIcon={false} styles={cardBodyStyle}>
               <div className='first__line'>
                   <span/>
                   <h5>Կարգավորումներ</h5>
                   <div style={{display: 'flex', alignItems: 'center', justifyItems: 'flex-end', width: "min-content", }}>
                      <CloseBtn onClose={onClose} styles={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}/>
                   </div>
               </div>
                <Switcher text='Նկարագրերի ցուցադրում' action={handleShowDescriptions} value={showDescriptions} />
                {/*<Switcher text='Ձայնային ուղեկցում' action={handleVoice} value={false} />*/}
                {/*<RadioGroup*/}
                {/*    className='radio__buttons'*/}
                {/*    onChange={handleChangeSpeaker}*/}
                {/*    aria-labelledby="demo-radio-buttons-group-label"*/}
                {/*    defaultValue={''}*/}
                {/*    name="radio-buttons-group"*/}
                {/*>*/}
                {/*    <FormControlLabel disabled={true} value="Male" control={<Radio  sx={sx}/>} label="Արամ" />*/}
                {/*    <FormControlLabel disabled={true} value="Female" control={<Radio sx={sx}/>} label="Աննա" />*/}
                {/*</RadioGroup>*/}
                <EditAndDelete type='edit' action={()=>handleEdit(true)}/>
                <EditAndDelete type='delete' action={handleDelete} />
            </CardBody>
        </div>
    );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    setSlidesItems: setSlidesItems
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SliderSettings);
