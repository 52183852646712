import axios from 'axios'
import mapboxgl from 'mapbox-gl'
import { getData } from '../utils'

const sanitizeData = (response) => {
    const resData = response.data.data[0]

    if (!response.data.accepted || !resData) {
        return
    }
    return resData
}
const sanitizeDataBystatus = (response) => {
    if (response.status !== 200) return
    return response.data
}
export const mapPreviewAPIRequest = (body) => {
    return axios
        .get(
            `${process.env.REACT_APP_INDEXING_URL}/api/Map/Preview${
                body && `?${body}`
            }`
        )
        .then(sanitizeData)
}

const mapPreviewTimeAPIRequest = (body) => {
    return axios.get(
        `${process.env.REACT_APP_INDEXING_URL}/api/Map/PreviewMap${
            body && `?${body}`
        }`
    )
}

const mapTimeAPIRequest = (body) => {
    return axios.get(
        `${process.env.REACT_APP_INDEXING_URL}/api/Map${body && `?${body}`}`
    )
}

export const getMapChangeRequest = (body, preview) => {
    if (preview) {
        return mapPreviewTimeAPIRequest(body)
    } else {
        return mapTimeAPIRequest(body)
    }
}

// export const mapLiveAPIRequest = (id, type) => {
//     return axios
//         .get(
//             `${process.env.REACT_APP_INDEXING_URL}/api/Map/${
//                 type.charAt(0).toUpperCase() + type.slice(1)
//             }/${id}`
//         )
//         .then(sanitizeData)
// }
export const mapLiveAPIRequest = (id, type) => {
    return axios
        .get(
            `${process.env.REACT_APP_GIS_URL}/Metadata/${
                type.charAt(0).toUpperCase() + type.slice(1) + 's'
            }/${id}`
        )
        .then(sanitizeDataBystatus)
}

export const mapLessonAPIRequest = (id, preview) => {
    return axios
        // .get(
        //     preview
        //         ? `${process.env.REACT_APP_INDEXING_URL}/api/Map/Preview?lessonId=${id}`
        //         : `${process.env.REACT_APP_INDEXING_URL}/api/Map/Lesson/${id}`
        // )
        .get(`${process.env.REACT_APP_GIS_URL}/Metadata/Lessons/${id}`)
        .then(sanitizeDataBystatus)
}
