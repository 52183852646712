import React from "react";

const ColorPicker = ({
                          isFill,
                          setIsFill,
                          opacity,
                          handleRangeChange,
                          colorsForColorPicker,
                          colorSelectHandler,
                          setIsOpenColorPicker,
                          backgroundColor
    }) => {
    const cycleStyle = (backgroundColor === '#FFFFFF') ? `
      .custom-slider-text-box::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 14px; /* Thumb width */
        height: 14px; /* Thumb height */
        background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.285 17.57C15.2038 17.57 17.57 15.2038 17.57 12.285C17.57 9.36617 15.2038 7 12.285 7C9.36617 7 7 9.36617 7 12.285C7 15.2038 9.36617 17.57 12.285 17.57Z" fill="%232c476c"/></svg>') no-repeat center;
        background-size: cover;
        cursor: pointer;
      }`
        : `
      .custom-slider-text-box::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 14px; /* Thumb width */
        height: 14px; /* Thumb height */
        background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.285 17.57C15.2038 17.57 17.57 15.2038 17.57 12.285C17.57 9.36617 15.2038 7 12.285 7C9.36617 7 7 9.36617 7 12.285C7 15.2038 9.36617 17.57 12.285 17.57Z" fill="white"/></svg>') no-repeat center;
        background-size: cover;
        cursor: pointer;
      }`;
    return (
    <div style={{height: isFill ? '110px' : '85px'}} className="color-and-opacity-picker-popup-text-box">
        <style>{cycleStyle}</style>
        <div className="fill-stroke-buttons">
            <div
                className={`fill-stroke-button ${!isFill && 'active'}`}
                onClick={() => {
                    setIsFill (false)
                    setIsOpenColorPicker(false)
                }}
            >
                Տեքստի գույն
            </div>
            <div
                className={`fill-stroke-button ${isFill && 'active'}`}
                onClick={() => {
                    setIsFill (true)
                    setIsOpenColorPicker(false)
                }}
            >
                Լցվածություն
            </div>
        </div>
        {isFill && (
            <div className={'opacity-selector'}>
                <div className="range-container">
                    <input
                        style={{
                            '--thumb-svg-fill': backgroundColor === '#ffffff' || backgroundColor !== 'white' ? 'black' : 'white',
                        }}
                        className="custom-slider-text-box"
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={opacity * 100}
                        onChange={handleRangeChange}
                    />
                    <div className={"checkerboard"}/>
                    <div className={"background"} style={{background: `linear-gradient(to right, transparent, ${backgroundColor})`}}/>
                </div>
            </div>
        )}
        <div className="color-picker-popup-colors">
            {colorsForColorPicker.map((color, i) => (
                <div
                    key={i}
                    className="painter_tools_color_data_item"
                    onClick={(e) => colorSelectHandler(e, color, isFill)}
                >
                    {i === colorsForColorPicker.length - 1 ? (
                        <div className="color_circle">
                            <svg
                                style={{
                                    background: "conic-gradient(from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )",
                                    borderRadius: "50%",
                                    height: 20,
                                    stroke: color.status ? '#3064ab' : '',
                                    strokeWidth: color.status ? "2" : "0",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                            />
                        </div>
                    ) : (
                        <div className="available-color">
                            <svg
                                style={{
                                    stroke: color.status ? '#3064ab' : color?.color,
                                    strokeWidth: color.status ? "2" : "0",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox={color.status ? "0 0 20 20" : "0 0 24 24"}
                                fill={color?.color}
                            >
                                <circle
                                    cx={color.status ? "10" : "12"}
                                    cy={color.status ? "10" : "12"}
                                    r={color.status ? "9" : "11.5"}
                                    stroke={color?.color}
                                />
                                <circle
                                    cx={color.status ? "10" : "12"}
                                    cy={color.status ? "10" : "12"}
                                    r={color.status ? "8" : "8.5"}
                                    fill={color?.color}
                                />
                            </svg>
                        </div>
                    )}
                </div>
            ))}
        </div>
    </div>
    )
};

export default ColorPicker
