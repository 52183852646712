import React, {memo, useEffect} from 'react';
import {connect} from "react-redux";

import {
    getDrawerToolboxClickedState, getEraserClickedState,
    getMapStyledDarkId, getMobileMenuState, getPainterGeoJsonDataST,
    getPainterStartData,
    getRulerClickedState,
    getRulerPopupShowST
} from "../../../store/selectors";
import {
    setCommentToolboxState,
    setDrawerToolboxClickedState, setEraserClickedState,
    setLineDrawToolboxState, setRulerClickedState
} from "../../../store/actions/painterStart";
import {setPainterGeoJsonDataST} from "../../../store/actions/mapStateAction";

import {changeCursorIcon, CURSOR_TYPE} from "../../../shared/mockData";
import EraserIcon from "../../../assets/imgs/PaintBar/eraser-icon.svg";

const EraserMockupComponent = (props) => {
    const {
        setEraserClickedState,
        getEraserClickedState,
        setDrawerToolboxClickedState,
        getPainterStartData,
        setLineDrawToolboxState,
        setCommentToolboxState,
        setRulerClickedState,
        map,
    } = props
    const handleIconClick = () => {
        setEraserClickedState(!getEraserClickedState);
        setDrawerToolboxClickedState(false);
        setLineDrawToolboxState(false);
        setCommentToolboxState(false);
        setRulerClickedState(false);
    };

    useEffect(() => {
        if (getEraserClickedState && getPainterStartData) {
            setTimeout(() => {
                changeCursorIcon(CURSOR_TYPE.ERASER);
                map["dragPan"].disable();
            }, 50);
        } else {
            changeCursorIcon();
            map["dragPan"].enable();
            setEraserClickedState(false);
        }
    }, [getEraserClickedState, getPainterStartData]);


    return (
        <div
            onClick={handleIconClick}
            className={`pain_items ${getEraserClickedState ? "button_active" : ""}`}
        >
            <img src={EraserIcon} alt="" className="icon_img"/>
        </div>
    );
};

const mapStateToProps = (state) => ({
    getRulerClickedState: getRulerClickedState(state),
    getPainterStartData: getPainterStartData(state),
    getRulerPopupShowST: getRulerPopupShowST(state),
    getMapStyledDarkId: getMapStyledDarkId(state),
    getMobileMenuState: getMobileMenuState(state),
    getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getEraserClickedState: getEraserClickedState(state),
})
const mapDispatchToProps = {
    setCommentToolboxState: setCommentToolboxState,
    setLineDrawToolboxState: setLineDrawToolboxState,
    setDrawerToolboxClickedState: setDrawerToolboxClickedState,
    setRulerClickedState: setRulerClickedState,
    setEraserClickedState: setEraserClickedState,
    setPainterGeoJsonDataST: setPainterGeoJsonDataST,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(EraserMockupComponent));