import {getLessonNavigationAPI, getLessonsAPI} from "../../service/lessons";
import { SET_LESSONS, SET_LESSON_NAVIGATION_DATA, LOADING_LESSONS, SET_LESSONS_TITLE, SET_LESSON_DATA } from "../constants";

export const loadingLessons = () => ({
  type: LOADING_LESSONS,
});

export const setLessons = (lessons) => ({
  type: SET_LESSONS,
  payload: lessons,
});

export const setLessonData = (data) => ({
  type: SET_LESSON_DATA,
  payload: data,
});
export const setLessonNavigationData = (data) => ({
  type: SET_LESSON_NAVIGATION_DATA,
  payload: data,
});

export const setLessonsTitle = (title) => ({
  type: SET_LESSONS_TITLE,
  payload: title,
});

export const getLessonsFromAPI = (url, body) => (dispatch, getState) => {
  dispatch(loadingLessons());
  const state = getState();

  getLessonsAPI(url, body, state).then((lessons) => {
    dispatch(setLessons(lessons));
  });
};
export const getLessonNavigationFromAPI = (id,topicId) => (dispatch) => {
  getLessonNavigationAPI(id,topicId).then((lessons) => {
    dispatch(setLessonNavigationData(lessons));
  });
};