import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import moment from "moment";
import {connect} from "react-redux";
import {useLocation, useSearchParams} from "react-router-dom";
import * as vis from "vis-timeline";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import Maximize from "../../assets/imgs/maximize.svg";
import Minimize from "../../assets/imgs/minimize.svg";
import {constructSelectedItemApiParams} from "../../pages/accountBoards/utils";
import {getArticleFromAPI, setArticles} from "../../store/actions/articles";
import {
    setNextID,
    setPrevID,
    setSelectedLesson,
} from "../../store/actions/handleLesson";
import {setHints} from "../../store/actions/hints";
import {
    getLessonNavigationFromAPI,
    setLessonsTitle,
} from "../../store/actions/lessons";
import {loadingMap} from "../../store/actions/map";
import {changeMapRequirements} from "../../store/actions/mapRequirements";
import {
    setTimeLienMovePos,
    setTimeLineCursorStatusST,
    setTimeLineEventDataST,
    setTimeLineMoveCountST,
    setTimeLineZoomIndexST,
    setTimeLIneZoomLevelST,
    setScreenShotButtonStateST,
    setTimeLineZoomST, setTimeLineHeightST, dispatchMapCenterPositionStatusST, setFirstTimeLineCursorStatusSTData,
} from "../../store/actions/mapStateAction";
import {changeMapStyles} from "../../store/actions/mapStyles";
import {setMenuExpend} from "../../store/actions/menuExpend";
import {setPainterStart} from "../../store/actions/painterStart";
import {setQuizPopup} from "../../store/actions/quizPopup";
import {setResetState} from "../../store/actions/reset";
import {setTimeLineExpend} from "../../store/actions/timeLineExpend";
import {
    getFilteredCategoriesData,
    getIsLessonTitle,
    getIsTimeLineLoading,
    getLessonData,
    getMapBounceData,
    getMapCenterCoordinatesST,
    getMapCenterCoordinatesStatusST,
    getMapRequirementsData,
    getMapStylesData,
    getNextLessonIDData,
    getPrevLessonIDData,
    getResetState,
    getScreenShotLoadingST,
    getScreenShotSendDataST,
    getScreenShotIntermediateDataST,
    getSelectedLessonId,
    getTimeLineCursorStatusST,
    getTimeLineEventDataST,
    getTimeLineExpendData,
    getTimeLineGroupsData,
    getTimeLineItemsData,
    getTimeLineMoveCountST,
    getTimeLineMovePositionST,
    getTimeLineMoveStatusST,
    getTimeLineOptionsData,
    getTimeLineZoomIndexST,
    getTimeLineZoomST,
    getTimeTineZoomLevelST,
    getTopicId,
    getTopicsData,
    getScreenShotButtonClickST,
    getMiddleTimeLineOptionsData,
    getMiddleTimeLineItemsData,
    getMiddleMaxTimeLineItemsData,
    getCategoriesData,
    getMaxTimeLineOptionsData,
    getTimeLineHeightST,
    getTopicNavigationData,
    getLessonNavigationData,
    getFirstTimeLineCursorStatusST,
    getBaseMapGroupIdST, getSelectedMapStyle, getSelectedArticleData,
} from "../../store/selectors";
import LessonArrow from "../UI/LessonArrow";
import TimeLineExtend from "../UI/TimeLineExtend";
import TopicArrow from "../UI/TopicArrow";
import {getTopicNavigationFromAPI, getTopicsFromAPI} from "./../../store/actions/topics";
import {debounce, } from "lodash";
import TimeLineClock from "../../assets/imgs/timeline_clock.js"
import ReactDOM from "react-dom";
import {clusteriser} from "../../utils/utils";
import * as turf from "@turf/turf";

let firstRed = true;

let zoomValue = 0;
const expandLevel = {
    2: 2,
    3: 3,
    4: 1
}
const expandLevelTopic = {
    2: 2,
    3: 1,
}

const debeounced1 = debounce((fn) => fn(), 1);

const Timeline = (props) => {
    const isMobile = window.innerWidth < 1024
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const isScreenShot = query.get("screenShot");
    const zoomInterval = useRef(null);

    const [timeLineMoveCountState, setTimeLineMoveCountState] = useState(0);
    const [isZooming, setIsZooming] = useState(false);

    useEffect(() => {
        setTimeLineMoveCountST(timeLineMoveCountState);
    }, [timeLineMoveCountState]);

    let [searchParams] = useSearchParams();
    let topicID = searchParams.get("topicID");
    let queryType = searchParams.get("type");

    const [prevAndNextButtonState, setPrevAndNextButtonState] = useState(true);
    const [topicTitle, setTopicTitle] = useState("");

    const timeLineContainer = React.createRef();
    const timeLineRealtedContainer = React.createRef();
    const timeLineLessonContainer = React.createRef();

    const timeLineHeaderRef = useRef(null);
    const timeLineExtendBtnRef = useRef(null);


    const prevTopicButton = useRef(null);
    const nextTopicButton = useRef(null);

    const [zoomIndex, setZoomIndex] = useState(0);

    const [firstLoad, setFirstLoad] = useState(true);
    const [firstMoveLoad, setFirstMoveLoad] = useState(true);
    const [firstTimeLineMovePosition, setFirstTimeLineMovePosition] =
        useState(true);

    const [firstTimeLineScrollZoomLoad, setFirstTimeLineScrollZoomLoad] =
        useState(true);
    const [heightIsMounted, setHeightIsMounted] = useState(false);
    const [scrollPosIsMounted, setScrollPosIsMounted] = useState(false);
    const [midBlockParamsIsMounted, setMidBlockParamsIsMounted] = useState(false);

    useEffect(() => {
        if (
            Object.keys(props.getTimeLineMovePositionST).length &&
            isScreenShot &&
            firstTimeLineMovePosition &&
            props.getTimeLineMoveStatusST
        ) {
            const params = {
                start: props.getTimeLineMovePositionST.start,
                end: props.getTimeLineMovePositionST.end,
                // min: props.getTimeLineMovePositionST.start,
                // max: props.getTimeLineMovePositionST.end,
            }
            setTimeout(() => {
                props.timeLine.current.setOptions?.(params);
            }, 0)
            setTimeout(() => {
                props.timeLineRelated.current?.setOptions?.(params);
            }, 1800)

            props.timeLineRelated.current?.redraw();
            props.timeLine.current.redraw();

            setFirstTimeLineMovePosition(false);
        }
    }, [firstTimeLineMovePosition, isScreenShot, props.getTimeLineMovePositionST, props.getTimeLineMoveStatusST, props.timeLine, props.timeLineRelated]);

    useEffect(() => {
        if (isScreenShot && props.getTimeLineHeightST && !heightIsMounted && props.timelineExtend === 3) {
            const elem = document.getElementsByClassName('timeline')[0]
            const height = props.getTimeLineHeightST;
            if (height < 230 || height > window.innerHeight) return
            elem.style.height = height + 'px'
             setTimeout(() => {
                 props.timeLineRelated.current?.setOptions?.({
                     height: height - 135 + 'px',
                     maxHeight: height - 135 + 'px',
                     minHeight: height - 135 + 'px',
                 });
                 props.timeLineRelated.current?.redraw();
             },500)
            setHeightIsMounted(true)
        }
    }, [props.getTimeLineHeightST, isScreenShot, props.timeLineRelated, heightIsMounted, props.timelineExtend])

    useEffect(() => {
        if (props.timeLine.current) return;

        if (queryType === 'topic') {
            props.timeLineRelated.current = new vis.Timeline(
                timeLineLessonContainer.current,
                props.middleTimeLineItems,
                props.middleTimeLineOptions
            );
        }
        else if (queryType === 'lesson') {
            const groups = [
                {
                    id: 11,
                    value: 11,
                    className: 'cat-11',
                },
                {
                    id: 10,
                    value: 10,
                    className: 'cat-10',

                },
                {
                    id: 9,
                    value: 9,
                    className: 'cat-9',
                },
                {
                    id: 8,
                    value: 8,
                    className: 'cat-8',
                }]
            props.timeLineRelated.current = new vis.Timeline(
                timeLineRealtedContainer.current,
                props.middleTimeLineItems,
                groups,
                props.middleTimeLineOptions
            );
        }
        props.timeLine.current = new vis.Timeline(
            timeLineContainer.current,
            props.timeLineItems,
            props.timeLineOptions
        );
        props.timeLine.current.__proto__.clusteriser = new clusteriser(props.timeLine.current)
        timeLineHeaderRef.current = null;
        timeLineExtendBtnRef.current = null;
    }, []);


    useEffect(() => {
        if (topicNavigation?.current?.id === topicID) return
        getTopicNavigationData();
    },[])

    useEffect(() => {
        props.timeLine.current.off("rangechanged", setTimeLineMovePos);
        props.timeLine.current.on("rangechanged", setTimeLineMovePos);
    }, []);

    const setTimeLineMovePos = (data) => {
        if (!data.byUser) return;
        const start = data.start;
        const end = data.end;

        const sendData = {
            start: start,
            end: end,
        };
        props.setTimeLienMovePos(sendData);
        props.setTimeLIneZoomLevelST(end - start);
    };

    const getTopicsData = () => {
        const {url, body} = constructSelectedItemApiParams(
            "grade",
            props.gradeID,
            props.subjectID
        );
        props.getTopicsFromAPI(url, body);
    };
    const getTopicNavigationData = () => {
        if(!topicID) return;
        props.getTopicNavigationFromAPI(topicID);
    };

    useEffect(() => {
        if (props.timeLineItems.length > 0) {
            setTimeout(() => {
                if (firstRed && props.filteredCategories.length !== 0) {
                    const clusters = props.timeLine.current.clusteriser.getClusters(props.timeLineItems);
                    const items = queryType === 'topic'
                        ? props.middleTimeLineItems
                        : props.handleGetItemVisibility(props.middleTimeLineItems)
                    props.timeLineRelated.current?.setData({
                        items: items,
                    })
                    props.timeLine.current.setData({
                        items: clusters,
                    });
                    firstRed = false;
                    props.runScreenShotStates?.(['leftBarSTF','timelineSTF','leftBarCatOpenSTF','compassSTF','loadDoneSTF'])
                }
                prevAndNextTopic();
            }, 1000);
        }

        return () => {
            // timeLineHeaderRef.current?.removeEventListener(
            //     "click",
            //     toggleMapTimeline
            // );
            timeLineExtendBtnRef.current?.removeEventListener(
                "click",
                toggleMapTimeline
            );

            prevTopicButton.current?.removeEventListener("click", handleGoToMapView);
            nextTopicButton.current?.removeEventListener("click", handleGoToMapView);
        };
    }, [props.timeLineLoading, props.timelineExtend, props.topics, props.filteredCategories]);
    useEffect(()=>{
        if(!firstRed && !!+isScreenShot){
            props.runScreenShotStates?.(['leftBarSTF','timelineSTF','compassSTF','loadDoneSTF'])
                .then(()=>{
                    props.filterLegend()
                })
        }
    }, [firstRed]);

    const prevAndNextTopic = () => {
        prevTopicButton.current = document.getElementById("left_topic_arrow_icon");
        nextTopicButton.current = document.getElementById("right_topic_arrow_icon");

        timeLineHeaderRef.current = document.getElementById("group_content");
        timeLineExtendBtnRef.current = document.getElementById(
            "timeline_extend_button"
        );

        // timeLineHeaderRef.current?.addEventListener("click", toggleMapTimeline);
        // timeLineExtendBtnRef.current?.addEventListener("click", toggleMapTimeline);
        if (prevTopicButton.current && !topicNavigation?.previous) {
            prevTopicButton.current.style.opacity = "0.5";
            prevTopicButton.current.style.cursor = "unset";
            prevTopicButton.current?.removeEventListener("click", handleGoToMapView);
        } else{
            prevTopicButton.current?.addEventListener(
                "click",
                handleGoToMapView.bind(this, ["prev"])
            );
        }

        if (nextTopicButton.current && !topicNavigation?.next) {
            nextTopicButton.current.style.opacity = "0.5";
            nextTopicButton.current.style.cursor = "unset";
            nextTopicButton.current?.removeEventListener("click", handleGoToMapView);
        }else{
            nextTopicButton.current?.addEventListener(
                "click",
                handleGoToMapView.bind(this, ["next"])
            );
        }
    };

    const handleGoToMapView = (args) => {
        if (!prevAndNextButtonState) return;
        setPrevAndNextButtonState(false);
        const type = args[0];
        let url;
        if(type === "next") url = `/map?type=topic&topicID=${topicNavigation?.next?.id}`
        if(type === "prev") url = `/map?type=topic&topicID=${topicNavigation?.previous?.id}`
        window.location.href = url;
    };

    // useEffect(() => {
    //   if (props.selectedLesson !== null) {
    //     props.timeLine.current.setData({
    //       items: [],
    //       groups: [],
    //     });
    //   }
    // }, [props.selectedLesson]);

    const changeURIParams = (id) => {
        let url = new URL(window.location.href);
        let params = new window.URLSearchParams(window.location.search);
        params.set("lessonID", id);
        url.search = params;
        url = url.toString();
        window.history.replaceState({url: url}, null, url);
        window.location.reload();
    };

  const toggleMapTimeline = (e) => {
    if(firstRed) return
    e.stopPropagation()
    const elem = document.getElementsByClassName('timeline')[0]
    if (elem.dataset.draggable === 'true') return;
    if (window.innerWidth < 1024) return;

    const queryParams = new URLSearchParams(window.location.search);
    const articleReadMode = queryParams.get("articleReadMode");
    // if (selectedLesson !== null) return;
    if (
        e?.target?.id === "left_topic_arrow_icon" ||
        e?.target?.id === "right_topic_arrow_icon" ||
        e?.target?.id === "topic_title" ||
        e?.target?.id === "lesson_topic_title" ||
        e?.target?.id === "lesson_title"
    ) return;
    props.setHints(false);
    props.setQuizPopup(false);
    if (articleReadMode !== "true") {

      const eventElement = e.target.closest('.event-timeline')
      props.setTimeLineExpend(
          queryType === 'topic'
          ? expandLevelTopic[props.timelineExtend + 1]
          : eventElement
                  ? 2
                  : expandLevel[props.timelineExtend + 1]
      );
      props.timeLine.current.setOptions?.({
        minHeight: props.timelineExtend === 1
            ? "90px"
            : props.timelineExtend === 2
                ? "90px"
                : "90px"
      });

        }
        props.timeLine.current.redraw();
    };

    const {
        selectedLesson,
        goToTopics,
        timelineExtend,
        timeLineLoading,
        setSelectedLesson,
        topics,
        topicNavigation,
        timeLineChanged,
        lessonNavigationData,
        setLessonsTitle,
        onTimeLineShowChange,
        topicId,
        setTimeLineZoomST,
        getTimeLineZoomST,
        getTimeLineMoveCountST,
        setTimeLineMoveCountST,
        handleTimeLineClickActionST,
        getTimeLineCursorStatusST,
        getFirstTimeLineCursorStatusST,
        getTimeLineEventDataST,
        getMapCenterCoordinatesST,
        getMapCenterCoordinatesStatusST,
        getScreenShotSendDataST,
        getScreenShotIntermediateDataST,
        getScreenShotButtonClickST,
        setTimeLineExpend,
        dispatchMapCenterPositionStatusST
    } = props;


    useEffect(() => {
        if (
            Object.keys(getMapCenterCoordinatesST).length > 0 &&
            isScreenShot &&
            getMapCenterCoordinatesStatusST
        ) {
            if(getMapCenterCoordinatesST.center){
                props.map.current.flyTo({
                    zoom: getMapCenterCoordinatesST.zoom,
                    center: getMapCenterCoordinatesST.center,
                    pitch: getMapCenterCoordinatesST.pitch,
                    bearing: getMapCenterCoordinatesST.bearing,
                    duration: 700, // Animation duration in milliseconds
                    easing: function (t) {
                        return t;
                    }
                });
            }else if(getMapCenterCoordinatesST.lng && getMapCenterCoordinatesST.lat){
                props.map.current.jumpTo({
                    center: [getMapCenterCoordinatesST.lng, getMapCenterCoordinatesST.lat],
                    zoom:4
                });
            }
            dispatchMapCenterPositionStatusST(false);
        }
    }, [getMapCenterCoordinatesST]);

    useEffect(() => {
        if (
            isScreenShot &&
            getTimeLineCursorStatusST &&
            getFirstTimeLineCursorStatusST &&
            Object.keys(getTimeLineEventDataST).length > 0
        ) {
            handleTimeLineClickActionST(getTimeLineEventDataST, isScreenShot);
            setTimeout(()=>{
            props.timeLine.current.setOptions?.({
                minHeight: "90px",
            });
            props.timeLine.current.redraw();

            if (props.timelineExtend > 1) {
                const t1 = document.querySelector(".t1");
                if(t1) t1.id = "t1";
            }
            const customTimeLine = document.querySelector(".t1")
            const timeLineClock = document.querySelector(".time-line-clock")
            if (!timeLineClock) {
                const rootElement = document.createElement("div")
                rootElement.className = 'time-line-clock'
                ReactDOM.render(<TimeLineClock/>, rootElement);
                customTimeLine?.appendChild(rootElement)
            }
            let time = new Date(getTimeLineEventDataST?.time);
            const activeEvent = props.timeLineItems?.getItemByDateRange?.(props.timeLine.current?.timeAxis?.step?.step || 1, time)
            const eventIcon = document.getElementById('event-icon')

            if (eventIcon) {
                eventIcon.remove()
            }

            if (activeEvent?.length === 1) {
                time = activeEvent[0].start
                const icon = document.createElement('i')
                icon.className = 'event-icon' + ' ' + activeEvent[0]?.elementType
                icon.id = 'event-icon'
                document.getElementsByClassName('t1')?.[0]?.appendChild(icon)
            }

            setTimeout(() => {
                const customTimeLineParams = customTimeLine?.getBoundingClientRect();
                document.querySelector('.iconic-clock-minute-hand')?.setAttribute('transform', `rotate(${customTimeLineParams?.left * 6},192,192)`);
                document.querySelector('.iconic-clock-hour-hand')?.setAttribute('transform', `rotate(${customTimeLineParams?.left},192,192)`);

            }, 2000)

            setFirstTimeLineCursorStatusSTData(false);
            },300)
        }
    }, [getTimeLineCursorStatusST, getTimeLineEventDataST]);

    useEffect(() => {
        if(!!props.timeLineGroups.length ){
            const groups =  props.timeLineGroups.map(cat=>{
                return {
                    id:cat.catId,
                    value:cat.catId,
                    className:`cat-${cat.catId}`,
                }
            })
            props.timeLineRelated.current?.setGroups(groups);
        }
    },[props.timeLineGroups])
    useEffect(() => {
        if (props.timelineExtend === 1) {
            const t1 = document.querySelector(".t1");
            if (t1?.id) {
                t1.id = "";
            }
        }
        else if (props.timelineExtend === 2) {
            const items = queryType === 'topic'
                ? props.middleTimeLineItems
                : props.handleGetItemVisibility(props.middleTimeLineItems)
            const options = queryType === 'topic'
                ? {
                    height: 90 + 'px',
                    maxHeight: 90 + 'px',
                    minHeight: 90 + 'px',
                    margin: {
                        item: {horizontal: 5, vertical: 5},
                        axis: 0
                    },
                    verticalScroll: true,
                    showMajorLabels: false,
                    showMinorLabels: false
                }
                : {
                    height: 90 + 'px',
                    maxHeight: 90 + 'px',
                    minHeight: 90 + 'px',
                    margin: {
                        item: {horizontal: 5, vertical: 5},
                        axis: 0
                    },
                    verticalScroll: true,
                    showMajorLabels: true,
                    showMinorLabels: true
                }

            props.timeLineRelated.current?.setOptions?.(options);
            props.timeLineRelated.current?.setData({
                items: items,
            })
            props.timeLineRelated.current?.redraw();
        }
        else if (props.timelineExtend === 3) {

      const items = props.handleGetItemVisibility(props.middleMaxTimeLineItems)
      props.timeLineRelated.current?.setOptions?.({
        height: 200 + 'px',
        maxHeight: 200 + 'px',
        minHeight: 200 + 'px',
        verticalScroll: true,
        margin: {
          item: {horizontal:5, vertical:5},
          axis: 5
        },
       showMajorLabels: false,
       showMinorLabels: false
      });
        props.timeLineRelated.current?.setData({
          items: items,
        })
        props.timeLineRelated.current?.redraw()
        }
    }, [props.timelineExtend]);

    useEffect(() => {
        if(lessonNavigationData &&  lessonNavigationData['current']?.id === +selectedLesson) return
        +selectedLesson && +topicId && props.getLessonNavigationFromAPI(+selectedLesson,+topicId)
        props.setNextID(lessonNavigationData['next']?.id || null);
        props.setPrevID(lessonNavigationData['previous']?.id || null);
    }, [selectedLesson,topicId]);



    const resetLessonView = () => {
        props.setResetState(!props.resetState);
        props.setPainterStart(false);
        props.setMenuExpend(1);
        props.setArticles([]);
        props.changeMapStyles(props.mapStyles[0].id);
        props.timeLine.current.removeCustomTime("t1");
        props.timeLine.current.addCustomTime(props.timeLineItems[0].start, "t1");
        props.handleTimeChange(props.timeLineItems[0].start);
        props.changeMapRequirements(props.mapRequirements[0].value);
        onTimeLineStartZoneHanler();
        props.timeLine.current.setOptions?.({
            start: new Date(
                props.timeLineItems[0].startFrom.setFullYear(
                    props.timeLineItems[0].startFrom.getFullYear()
                )
            ),
            end: new Date(
                props.timeLineItems[0].endTo.setFullYear(
                    props.timeLineItems[0].endTo.getFullYear()
                )
            ),
            min: new Date(
                props.timeLineItems[0].startFrom.setFullYear(
                    props.timeLineItems[0].startFrom.getFullYear()
                )
            ),
            max: new Date(
                props.timeLineItems[0].endTo.setFullYear(
                    props.timeLineItems[0].endTo.getFullYear()
                )
            ),
        });
        props.timeLineRelated.current?.setOptions?.({
            start: new Date(
                props.timeLineItems[0].startFrom.setFullYear(
                    props.timeLineItems[0].startFrom.getFullYear()
                )
            ),
            end: new Date(
                props.timeLineItems[0].endTo.setFullYear(
                    props.timeLineItems[0].endTo.getFullYear()
                )
            ),
            min: new Date(
                props.timeLineItems[0].startFrom.setFullYear(
                    props.timeLineItems[0].startFrom.getFullYear()
                )
            ),
            max: new Date(
                props.timeLineItems[0].endTo.setFullYear(
                    props.timeLineItems[0].endTo.getFullYear()
                )
            ),
        });
        props.timeLine.current.redraw();
        props.timeLineRelated.current?.redraw();
        props.map.current.transform.resetCompass();

        props.map.current.fitBounds(props.mapBounce, {
            pitch: 0,
            maxZoom: 7,
            padding: {top: 40, bottom: 200, left: 40, right: 40},
            duration: 1000,
        });

        const hiddenCategories = props.filteredCategories.filter(
            (cat) => cat.show === false
        );
        hiddenCategories.forEach((item) => {
            props.toggleItemsFromMap(item.id, null, "category");
        });
    };

    useEffect(() => {
        setTopicTitle(topics.find((item) => item.value === +topicID));
    }, [topics]);

    const timeLineStartZoneRef = useRef(null);

    useEffect(() => {
        !timeLineLoading && onTimeLineStartZoneHanler();

        timeLineStartZoneRef?.current?.addEventListener(
            "click",
            onTimeLineStartZoneHanler
        );
        props.setPrevID(lessonNavigationData['previous']?.id || null);
        props.timeLine.current.on("timechange", (properties) => {
            const customTimeLine = document.querySelector(".t1")
            const timeLineClock = document.querySelector(".time-line-clock")
            if (!timeLineClock) {
                const rootElement = document.createElement("div")
                rootElement.className = 'time-line-clock'
                ReactDOM.render(<TimeLineClock/>, rootElement);
                customTimeLine?.appendChild(rootElement)
            }
            const customTimeLineParams = customTimeLine?.getBoundingClientRect();
            document.querySelector('.iconic-clock-minute-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left * 6},192,192)`);
            document.querySelector('.iconic-clock-hour-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left},192,192)`);

            const timeLineStartZoneParams =
                timeLineStartZoneRef?.current?.getBoundingClientRect();
            if (
                customTimeLineParams?.x - timeLineStartZoneParams?.width <=
                timeLineStartZoneParams?.x
            ) {
                const ms = new Date(properties.time).getTime();
                const changeMs = new Date(ms - 3.154e10);
                onTimeLineStartZoneHanler(changeMs);
            }
        });

        return () => {
            timeLineStartZoneRef?.current?.removeEventListener(
                "click",
                onTimeLineStartZoneHanler
            );
        };
    }, [timeLineLoading, selectedLesson]);

    useEffect(() => {
        isScreenShot &&
        document.body.addEventListener("click", onDocumentBodyClick);

        return () => {
            document.body.removeEventListener("click", onDocumentBodyClick);
        };
    }, []);

    const onDocumentBodyClick = () => {
        props.setScreenShotButtonStateST(true);

        document.body.removeEventListener("click", onDocumentBodyClick);
    };

    const onTimeLineStartZoneHanler = (time) => {
        props.setTimeLineCursorStatusST(null);

        props.setTimeLineEventDataST({});

        onTimeLineShowChange();
        try {
            props?.timeLine?.current?.removeCustomTime("t1");
            props.timeLine.current.redraw();

            let timeStart;
            if (time || props.timeLineItems[0]?.start) {
                timeStart = time || props.timeLineItems[0]?.start;
            }
            if (!timeLineChanged) {
                timeStart = props?.timeLine?.current?.options?.start;
            }
            const selectedMapStyle =props.selectedMapStyle?.active
            const styleId = props.selectedMapStyle?.[selectedMapStyle]

            const startYear = new Date(timeStart).getFullYear()
            const endYear = new Date(props.timeLineItems[0]?.end).getFullYear()
            const startYearForFilter = startYear <= endYear ? startYear : endYear
            const endYearForFilter = startYear <= endYear ? endYear : startYear
            const gisApiSources = props.map.current.getStyle().sources['Brainograph PIN GIS API']
            if (!gisApiSources) return;

            const source = gisApiSources.data?.features.map(el => {
                el.properties.startTime = startYearForFilter
                el.properties.endTime = endYearForFilter
                return el
            });
            props.handleSetSuperCluster(source)
            props.map.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(source)
            // const layers = props.map.current.getStyle();

            const dateTime = new Date(timeStart)
            const dateTimeYear = dateTime.getFullYear().toString().replace('-', '')
            // const dateTimeMonth = dateTime.getMonth() || 1
            // const dateTimeDay = dateTime.getDay() || 1
            // const dateTimeHour = dateTime.getHours() || 1
            // const dateTimeMinute = dateTime.getMinutes() || 1
            // const dateTimeSecond = dateTime.getSeconds() || 1
            const dateTimeIsBc = dateTime.getFullYear().toString().includes('-')
            const tileUrl = new URL(props.map.current.getStyle()
                .sources["Brainograph GIS API"].tiles[0]);
            const queryParams =  new URLSearchParams(window.location.search);
            tileUrl.search = ''
            tileUrl.searchParams.append(`${queryParams.get("type")}id`, `${queryParams.get(`${queryParams.get("type")}ID`)}`)
            tileUrl.searchParams.append('baseMapId', styleId)
            tileUrl.searchParams.append('showDraft', 'False')
            tileUrl.searchParams.append('year', dateTimeYear)
            // tileUrl.searchParams.append('Month', dateTimeMonth)
            // tileUrl.searchParams.append('Day', dateTimeDay)
            // tileUrl.searchParams.append('Hour', dateTimeHour)
            // tileUrl.searchParams.append('Minute', dateTimeMinute)
            // tileUrl.searchParams.append('Second', dateTimeSecond)
            tileUrl.searchParams.append('isBc', dateTimeIsBc)
            props.map.current.getSource("Brainograph GIS API")
                .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}${tileUrl.search}`]);

            props?.timeLine?.current?.addCustomTime(timeStart, "t1");
            props.onMapRenderComplete(props.map.current,props.filterLegend)
        } catch (e) {
            // console.log("e", e);
        }
    };

    const lessonTitleRef = useRef("");

    useEffect(() => {
        const type = searchParams.get("type");
        if (selectedLesson !== null || type === "article") {
            onTimeLineShowChange();
            props.setHints(false);
            props.setQuizPopup(false);
            props.setTimeLineExpend(1);
            props.timeLine.current.setOptions?.({
                minHeight: "90px",
            });
            props.timeLine.current.redraw();
        }
    }, [selectedLesson]);

    const onSetSelectedLesson = (state) => {
        props.loadingMap(true);
        setSelectedLesson(state);
        window.location.href = `/map?type=lesson&lessonID=${state}`
        props.setNextID(lessonNavigationData['next']?.id || null);
        props.setPrevID(lessonNavigationData['previous']?.id || null);
    };

    useEffect(() => {
        if (Object.keys(lessonNavigationData).length > 0) {
            lessonTitleRef.current = lessonNavigationData['current']?.title;
            setLessonsTitle(lessonNavigationData['current']?.title);
        }
    }, [selectedLesson, lessonNavigationData]);

    useEffect(() => {
        if (!props.timeLine.current) return false;
        const timeline = props.timeLine.current;
        timeline.on("rangechanged", ({start, end}) => {

      const { min, max } = timeline.getItemRange();
      let a0 = 1;
      let a100 = moment
        .duration(moment(max).diff(moment(min)))
        .asMilliseconds();
      let distance = (a100 - a0) / 1000;
      start = moment(start);
      end = moment(end);
      const duration = moment.duration(end.diff(start));
      const mins = duration.asMilliseconds();
      // Arithmatic progression variables
      if (mins !== 0) {
        const x = (mins - a0) / distance; // Arithmatic progression formula
        // setTimeLineZoomIndexST(Math.abs(Math.ceil(x / 100) - 10));
        setZoomIndex(Math.abs(Math.ceil(x / 100) - 13));
      } else {
        // setTimeLineZoomIndexST(0);
        setZoomIndex(0);
      }
    });
  }, [props.timeLine.current]);

  useEffect(()=>{
      if (props.timeLine.current && props.timeLineItems.length > 0) {
        const timeline = props.timeLine.current;
        timeline.on("rangechanged", () => {
            const clusters = props.timeLine.current.clusteriser.getClusters(props.timeLineItems)
            props.timeLine.current.setData({
                items: clusters,
            });
            props.timeLine.current.redraw();

        })
      }
  },[props.timeLineItems, props.timeLine.current])
    const onStartZoomIn = (value) => {
        if (zoomValue > 4.5) {
            zoomValue = 4.5;
        }
        if (window.innerWidth < 1024) return;
        props.timeLineRelated.current?.zoomIn(value);
        zoomValue = zoomValue + 0.5;
        setTimeout(() => {
            const rangeParams = {
                start:new Date(props.timeLine.current?.timeAxis.step?._start),
                end:new Date(props.timeLine.current?.timeAxis.step?._end),
            }
            props.setTimeLienMovePos(rangeParams);
        },500)
    };

    const onStartZoomOut = (value) => {
        if (zoomValue < 0.5) {
            zoomValue = 0.5;
        }
        if (window.innerWidth < 1024) return;
        props.timeLine.current?.zoomOut(value);
        props.timeLineRelated.current?.zoomOut(value);
        zoomValue = zoomValue - 0.5;
        setTimeout(() => {
            const rangeParams = {
                start:new Date(props.timeLine.current?.timeAxis.step?._start),
                end:new Date(props.timeLine.current?.timeAxis.step?._end),
            }
            props.setTimeLienMovePos(rangeParams);
        },500)

    };

    const startZoomIn = () => {
        setIsZooming(true);
        zoomInterval.current = setInterval(() => {
            onStartZoomIn(0.5);
        }, 100);
    };

    const startZoomOut = () => {
        setIsZooming(true);
        zoomInterval.current = setInterval(() => {
            onStartZoomOut(0.5);
        }, 100);
    };

    const stopZoom = () => {
        if (zoomInterval.current) {
            clearInterval(zoomInterval.current);
            zoomInterval.current = null;
            setIsZooming(false);
        }
    };

    useEffect(() => {
        const zoomInButton = document.querySelector(".scroll_and_line_change_buttons_plus");
        const zoomOutButton = document.querySelector(".scroll_and_line_change_buttons_minus");

        const handleMouseDownIn = () => {
            startZoomIn();
        };

        const handleMouseDownOut = () => {
            startZoomOut();
        };

        const handleMouseUp = () => {
            stopZoom();
        };

        zoomInButton.addEventListener("mousedown", handleMouseDownIn);
        zoomOutButton.addEventListener("mousedown", handleMouseDownOut);
        document.addEventListener("mouseup", handleMouseUp);

        zoomInButton.addEventListener("click", () => {
            if (!isZooming) onStartZoomIn(0.5);
        });

        zoomOutButton.addEventListener("click", () => {
            if (!isZooming) onStartZoomOut(0.5);
        });

        return () => {
            zoomInButton.removeEventListener("mousedown", handleMouseDownIn);
            zoomOutButton.removeEventListener("mousedown", handleMouseDownOut);
            document.removeEventListener("mouseup", handleMouseUp);
            zoomInButton.removeEventListener("click", onStartZoomIn);
            zoomOutButton.removeEventListener("click", onStartZoomOut);
        };
    }, [isZooming]);

    useEffect(() => {
        if (firstMoveLoad && getTimeLineMoveCountST && isScreenShot) {
            onStartMove(getTimeLineMoveCountST);
            setFirstMoveLoad(false);
        }
    }, [getTimeLineMoveCountST]);

    const onStartMove = (percentage) => {
        if (!props.timeLine.current) return;
        if (percentage < 0) {
            let index = 1;

            setTimeout(() => {
                for (let i = 0; i < Math.abs(percentage); i++) {
                    setTimeout(() => {
                        let range = props.timeLine.current.getWindow();
                        let interval = range.end - range.start;
                        props.timeLine.current.setWindow({
                            start: range.start.valueOf() + interval * 0.2,
                            end: range.end.valueOf() + interval * 0.2,
                        });
                        props.timeLineRelated.current?.setWindow({
                            start: range.start.valueOf() + interval * 0.2,
                            end: range.end.valueOf() + interval * 0.2,
                        });
                    }, 700 * index);

                    index++;
                }
            }, 2000);
        } else {
            let index = 1;

            setTimeout(() => {
                for (let i = 0; i < Math.abs(percentage); i++) {
                    setTimeout(() => {
                        let range = props.timeLine.current.getWindow();
                        let interval = range.end - range.start;

                        props.timeLine.current.setWindow({
                            start: range.start.valueOf() - interval * 0.2,
                            end: range.end.valueOf() - interval * 0.2,
                        });
                        props.timeLineRelated.current?.setWindow({
                            start: range.start.valueOf() - interval * 0.2,
                            end: range.end.valueOf() - interval * 0.2,
                        });
                    }, 700 * index);

                    index++;
                }
            }, 2000);
        }
    };

    function handleBeforeUnload(e) {
        e.preventDefault();
        e.returnValue = "Are you sure you want to leave?";
    }

    useEffect(() => {
        if (
            !isNaN(+isScreenShot) &&
            isScreenShot &&
            !!getScreenShotSendDataST.length > 0 &&
            !!getScreenShotIntermediateDataST.length > 0 &&
            getScreenShotSendDataST !== getScreenShotIntermediateDataST
        ) {
            window.onbeforeunload = handleBeforeUnload;
        } else {
            window.onbeforeunload = null;
        }
    }, [getScreenShotSendDataST, getScreenShotIntermediateDataST]);

    useEffect(() => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [getScreenShotButtonClickST]);

    useEffect(() => {
        if (isMobile) {
            setTimeLineExpend(1);
        }
    }, [isMobile]);

    function addMove(e) {
        if (timelineExtend !== 3) return
        window.addEventListener('mousemove', resize)
        window.addEventListener('touchmove', resize)
        window.addEventListener('mouseup', stopResize)
        window.addEventListener('touchend', stopResize)
    }

    function resize(e) {
        return debeounced1(() => {
            const elem = document.getElementsByClassName('timeline')[0]
            const elemBoundingClientRect = elem.getBoundingClientRect()
            let height = 135
            if(e.type === 'mousemove') {
                height = Math.abs(e.pageY - elemBoundingClientRect.top - elemBoundingClientRect.height);
            }
            if(e.type === 'touchmove') {
                height = Math.abs(e?.changedTouches[0]?.pageY - elemBoundingClientRect.top - elemBoundingClientRect.height);
            }
            props.setTimeLineHeightST(height)
            if (height < 230 || height > window.innerHeight) return
            elem.dataset.draggable = true
            elem.style.height = height + 'px'
            props.timeLineRelated.current?.setOptions?.({
                height: height - 135 + 'px',
                maxHeight: height - 135 + 'px',
                minHeight: height - 135 + 'px',
            });
            props.timeLineRelated.current?.redraw();

        })
    }
    function stopResize(e) {

      const elem = document.getElementsByClassName('timeline')[0]
    debeounced1(()=> {
        elem.dataset.draggable = false
      })
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('touchmove', resize)
      window.removeEventListener('mouseup', stopResize)
      window.removeEventListener('touchend', stopResize)
  }

  function handleTimeLineClick (e) {
    const element = e.target.closest('.timeline-cluster')
    const eventElement = e.target.closest('.event-timeline')
    if(element){
      const scaleStart = element.dataset.scaleStart
      const scaleEnd = element.dataset.scaleEnd
      props.timeLine.current.setWindow(new Date().setFullYear(scaleStart),new Date().setFullYear(scaleEnd))
      props.timeLineRelated.current?.setWindow(new Date().setFullYear(scaleStart),new Date().setFullYear(scaleEnd))
      setTimeout(() => {
        const rangeParams = {
          start:new Date(props.timeLine.current?.timeAxis.step?._start),
          end:new Date(props.timeLine.current?.timeAxis.step?._end),
        }
        props.setTimeLienMovePos(rangeParams);
      },500)
    }
    // if(eventElement) {
    //   e.stopPropagation()
    //   const bbox = turf.bbox(JSON.parse(eventElement.dataset.bounds))
    //   props.map.current.syncMapAndCompassStart()
    //   props.map.current.on('idle',props.map.current.syncMapAndCompassEnd)
    //   props.map.current.fitBounds(bbox,{padding: 20})
    //   if(eventElement?.dataset?.openMiddleTimeline === 'true' && props.timelineExtend !== 2){
    //     toggleMapTimeline(e)
    //   }
    //   setTimeout(() => {
    //     const rangeParams = {
    //       start:new Date(props.timeLine.current?.timeAxis.step?._start),
    //       end:new Date(props.timeLine.current?.timeAxis.step?._end),
    //     }
    //     props.setTimeLienMovePos(rangeParams);
    //   },500)
    // }
  }
  return (
    <div>
      <footer
        onMouseDown={addMove}
        onTouchStart={addMove}
        className={`footer timeline ${timelineExtend > 1 || (timelineExtend > 1 && !isMobile) ? "expand" : ""} ${
          selectedLesson !== null ? "lesson-timeline" : ""
        }`}
        style={{
          backgroundColor: "#F1F1F1",
          // width: `${window.innerWidth * 0.957}px`,
          width:
            window.innerWidth < 1024
              ? `calc(${window.innerWidth}px - 5px)`
              : `calc(${window.innerWidth}px - 5px)`,
          height: timelineExtend === 1 || (timelineExtend === 1 && !isMobile)
              ? "135px"
              : timelineExtend === 2 || (timelineExtend === 2 && !isMobile)
                  ?"230px"
                  :"335px"
        }}
      >

                  {(queryType === 'topic'  || queryType === 'lesson') &&(<>
                      <div id="group_content"
                           className={`group-content ${"active"}`}
                           style={{'--loader-color': '#506B90'}}
                           data-is-loading={timeLineLoading || props.globalLoading}
                      >
                            <span id="left_topic_arrow_icon" className="left_topic_arrow_icon">
                              <TopicArrow/>
                            </span>
                          <p id="topic_title">{topicNavigation?.current?.title}</p>
                          <span
                              id="right_topic_arrow_icon"
                              className="right_topic_arrow_icon"
                          >
                              <TopicArrow/>
                            </span>
                      </div>
                      <div className="timeline__btns">
                          <button
                              id="timeline_extend_button"
                              className="timeline__btn timeline__expand"
                              type="button"
                              onMouseDown={toggleMapTimeline}
                          >
                              {<TimeLineExtend expandLevel={timelineExtend}/>}
                          </button>

                      </div>
                  </>)}
                  {queryType === 'article' && (
                      <div id="group_content"
                           className={`group-content ${"active"}`}
                           style={{'--loader-color': '#506B90'}}
                           data-is-loading={timeLineLoading || props.globalLoading}
                      >
                          <p id="article_title">{props.mapData?.content?.[0]?.shortTitle || props.mapData?.content?.[0]?.mainTitle }</p>
                      </div>
                  )}
          {(selectedLesson !== null && !isMobile) && (
              <>
                  <div
                      className={`lesson ${timeLineLoading ? "disable-timeline" : ""}`}
                      data-is-loading={timeLineLoading || props.globalLoading}
                      style={{
                          height: timelineExtend === 1 || (timelineExtend === 1 && isMobile) ? "35px" : "100%",
                          cursor: timelineExtend === 3 ? 'all-scroll' : '',
                          '--loader-color': '#506B90'
                      }}
                  >
                      <div className="lesson__names">
                          <div className="lesson__name" id="group_content_lesson">
                                  <span
                                      className="lesson__name_left_arrow"
                                      id="left_topic_arrow_icon"
                                      onClick={() => {
                                          !!lessonNavigationData?.previous && onSetSelectedLesson(lessonNavigationData?.previous?.id);
                                      }}
                                      style={{
                                          opacity: !lessonNavigationData?.previous ? 0.7 : 1,
                                      }}
                                  >
                                    <LessonArrow/>
                                  </span>
                                    <p id="lesson_title" onClick={resetLessonView}>
                                        <span>Դաս - </span>
                                        {
                                            lessonNavigationData?.current?.title
                                        }
                                    </p>
                                    <p className="lesson_slash">|</p>
                                    <p id="lesson_topic_title" onClick={goToTopics}>
                                        <span>Թեմա - </span>
                                        {
                                            topicNavigation?.current?.title
                                        }
                                    </p>
                                    <span
                                        className="lesson__name_right_arrow"
                                        id="right_topic_arrow_icon"
                                        onClick={() => {
                                            !!lessonNavigationData?.next && onSetSelectedLesson(lessonNavigationData?.next?.id);
                                        }}
                                        style={{
                                            opacity: !lessonNavigationData?.next ? 0.7 : 1,
                                        }}
                                    >
                    <LessonArrow/>
                  </span>
                                </div>
                            </div>
                            {/*<div*/}
                            {/*  className="lesson__content"*/}
                            {/*  style={{*/}
                            {/*    visibility: timelineExtend === 1 || (timelineExtend === 1 && !isMobile)? "visible" : "hidden",*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  <div className="lesson__content-catwrap custom-scroll">*/}
                            {/*    <div className="lesson__content-categories">*/}
                            {/*      {timeLineGroups.map((item, index) => {*/}
                            {/*        return (*/}
                            {/*          <div className="lesson__content-cat" key={item.id}>*/}
                            {/*            <label className="main__subitem-label">*/}
                            {/*              <button*/}
                            {/*                onClick={() =>*/}
                            {/*                  toggleItemsFromMap(item.catId, null, "category")*/}
                            {/*                }*/}
                            {/*              >*/}
                            {/*                <input*/}
                            {/*                  className="main__subitem-checkbox show-checkbox"*/}
                            {/*                  type="checkbox"*/}
                            {/*                  defaultChecked={item?.show}*/}
                            {/*                />*/}
                            {/*                <Eye show={item?.show} />*/}
                            {/*              </button>*/}
                            {/*              <span*/}
                            {/*                className="lesson__content-svg"*/}
                            {/*                style={{*/}
                            {/*                  opacity: item?.show ? 1 : 0.5,*/}
                            {/*                }}*/}
                            {/*              >*/}
                            {/*                {!item?.isLamp ? (*/}
                            {/*                  <img*/}
                            {/*                    src={item?.catIcon}*/}
                            {/*                    style={{*/}
                            {/*                      width: 25,*/}
                            {/*                      height: 25,*/}
                            {/*                    }}*/}
                            {/*                  />*/}
                            {/*                ) : (*/}
                            {/*                  <img*/}
                            {/*                    src={LampIcon}*/}
                            {/*                    style={{*/}
                            {/*                      width: "auto",*/}
                            {/*                      height: 25,*/}
                            {/*                    }}*/}
                            {/*                  />*/}
                            {/*                )}*/}
                            {/*              </span>*/}
                            {/*            </label>*/}
                            {/*            <div className="lesson__content-items persons custom-scroll2">*/}
                            {/*              {timeLineItems*/}
                            {/*                .filter(*/}
                            {/*                  (x) =>*/}
                            {/*                    (item.isLamp && x.isLamp) ||*/}
                            {/*                    (!x.isLamp &&*/}
                            {/*                      x.catId === item.catId &&*/}
                            {/*                      x.className === "isInner")*/}
                            {/*                )*/}
                            {/*                .map((article) => {*/}
                            {/*                  return (*/}
                            {/*                    <div*/}
                            {/*                      className="lesson__content-item"*/}
                            {/*                      key={article.id}*/}
                            {/*                      style={{*/}
                            {/*                        backgroundColor: article.color*/}
                            {/*                          ? article.color*/}
                            {/*                          : "",*/}
                            {/*                        opacity: article.show ? 1 : 0.5,*/}
                            {/*                      }}*/}
                            {/*                      onClick={() =>*/}
                            {/*                        getArticleFromAPI(article.articleId)*/}
                            {/*                      }*/}
                            {/*                    >*/}
                            {/*                      <span className="lesson__content-name">*/}
                            {/*                        {article.isLamp ? (*/}
                            {/*                          <img*/}
                            {/*                            src={LampIcon}*/}
                            {/*                            style={{*/}
                            {/*                              width: 15,*/}
                            {/*                              height: 20,*/}
                            {/*                              marginRight: 10,*/}
                            {/*                            }}*/}
                            {/*                          />*/}
                            {/*                        ) : (*/}
                            {/*                          <img*/}
                            {/*                            src={article?.catIcon}*/}
                            {/*                            style={{*/}
                            {/*                              width: 20,*/}
                            {/*                              height: 20,*/}
                            {/*                              marginRight: 10,*/}
                            {/*                            }}*/}
                            {/*                          />*/}
                            {/*                        )}*/}
                            {/*                        {article.content}*/}
                            {/*                      </span>*/}
                            {/*                    </div>*/}
                            {/*                  );*/}
                            {/*                })}*/}
                            {/*            </div>*/}
                            {/*          </div>*/}
                            {/*        );*/}
                            {/*      })}*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                        </div>
                    </>
                )}
                {
                    queryType === 'topic'
                        ? (
                            <div
                                className={`timeline__content__lesson`}
                                ref={timeLineLessonContainer}
                                data-is-loading={timeLineLoading || props.globalLoading}
                                style={{
                                    '--loader-color':'#ececec ',
                                    height: timelineExtend === 1 || (timelineExtend === 1 && !isMobile)
                                        ? "0"
                                        : timelineExtend === 2 || (timelineExtend === 2 && !isMobile)
                                            ? "90px"
                                            : "calc(100% - 135px)"
                                }}
                            ></div>
                        )
                        : (<div
                            className={timelineExtend === 3
                                ? `timeline__content__all__related__max`
                                : `timeline__content__all__related`
                            }
                            ref={timeLineRealtedContainer}
                            data-is-loading={timeLineLoading || props.globalLoading}
                            style={{
                                '--loader-color':'#ececec ',
                                height: timelineExtend === 1 || (timelineExtend === 1 && !isMobile)
                                    ? "0"
                                    : timelineExtend === 2 || (timelineExtend === 2 && !isMobile)
                                        ? "90px"
                                        : "calc(100% - 135px)"
                            }}
                        ></div>)
                }


        <div
          onClick={handleTimeLineClick}
          ref={timeLineContainer}
          className={`timeline__content ${
            selectedLesson !== null ? "inner" : ""
          } ${timeLineLoading ? "disable-timeline" : ""}`}
          data-is-loading={timeLineLoading || props.globalLoading}
          style={{
            height: timelineExtend > 1 || (timelineExtend > 1 && !isMobile)? "100px" : "calc(100% - 35px)",
            opacity: timeLineLoading ? 0 : 1,
            '--loader-color':'#ececec '
          }}
        >
          <div
            className="timeline_start_porsition_section"
            data-is-loading={timeLineLoading || props.globalLoading}
            style={{
              height: timelineExtend > 1 || (timelineExtend > 1 && !isMobile)? "100px" : "100%",
              '--loader-color':'#ececec '
            }}
            id="timeline_start_porsition_section"
            ref={timeLineStartZoneRef}
          >
            <div
              className="left_border"
              style={{
                height: "100%",
                borderRight: !timeLineChanged
                  ? "1.33px solid #2c476c"
                  : "1.33px solid #d6dbe3",
              }}
            ></div>
            <div
              className={`${!timeLineChanged ? 'active' : ''} timeline_start_porsition_section_section`}
            ></div>
          </div>
          <div className="scroll_and_line_change scroll_and_line_change_left_border"
               style={{'--loader-color':'#ececec '}}
               data-is-loading={timeLineLoading || props.globalLoading}>
            <div className="scroll_and_line_change_buttons">
                <div
                    className="scroll_and_line_change_buttons_plus">
                    <img src={Maximize} className="icon_maximize" />
                </div>
              <div
                className="scroll_and_line_change_buttons_minus"
                style={{ opacity: zoomValue <= 0 ? 0.5 : 1 }}
              >
                <img src={Minimize} className="icon_minimize" />
              </div>
            </div>
          </div>
        </div>
        {/*{timeLineLoading || props.globalLoading ? (*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      position: "absolute",*/}
        {/*      width: "100%",*/}
        {/*      height: "100%",*/}
        {/*      top: 0,*/}
        {/*      display: "flex",*/}
        {/*      justifyContent: "center",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <PageLoader />*/}
        {/*  </div>*/}
        {/*) : null}*/}
      </footer>
      {/*<div*/}
      {/*  className={`timeline__arrow timeline__prev ${*/}
      {/*    timeLineLoading ? "disable-timeline" : ""*/}
      {/*  }`}*/}
      {/*  type="button"*/}
      {/*  onClick={() => {*/}
      {/*    let res = getTimeLineMoveCountST - 1;*/}

            {/*    setTimeLineMoveCountST(res);*/}

            {/*    moveRight();*/}
            {/*  }}*/}
            {/*  disabled={timeLineLoading && zoomIndex === 0}*/}
            {/*  style={{ opacity: zoomIndex === 0 ? 0.5 : 1 }}*/}
            {/*>*/}
            {/*  <TimelineArrow />*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className={`timeline__arrow timeline__next ${*/}
            {/*    timeLineLoading ? "disable-timeline" : ""*/}
            {/*  }`}*/}
            {/*  type="button"*/}
            {/*  onClick={() => {*/}
            {/*    let res = getTimeLineMoveCountST + 1;*/}

            {/*    setTimeLineMoveCountST(res);*/}

            {/*    moveLeft();*/}
            {/*  }}*/}
            {/*  disabled={timeLineLoading && zoomIndex === 0}*/}
            {/*  style={{ opacity: zoomIndex === 0 ? 0.5 : 1 }}*/}
            {/*>*/}
            {/*  <TimelineArrow />*/}
            {/*</div>*/}
        </div>
    );
};

const mapStateTopProps = (state) => ({
    timelineExtend: getTimeLineExpendData(state),
    timeLineOptions: getTimeLineOptionsData(state),
    middleTimeLineOptions: getMiddleTimeLineOptionsData(state),
    maxTimeLineOptions: getMaxTimeLineOptionsData(state),
    prevLesson: getPrevLessonIDData(state),
    nextLesson: getNextLessonIDData(state),
    timeLineItems: getTimeLineItemsData(state),
    middleTimeLineItems: getMiddleTimeLineItemsData(state),
    middleMaxTimeLineItems: getMiddleMaxTimeLineItemsData(state),
    timeLineGroups: getTimeLineGroupsData(state),
    timeLineLoading: getIsTimeLineLoading(state),
    selectedArticle: getSelectedArticleData(state),
    selectedLesson: getSelectedLessonId(state),
    topics: getTopicsData(state),
    topicNavigation: getTopicNavigationData(state),
    mapStyles: getMapStylesData(state),
    mapRequirements: getMapRequirementsData(state),
    filteredCategories: getFilteredCategoriesData(state),
    categories: getCategoriesData(state),
    mapBounce: getMapBounceData(state),
    lessonNavigationData: getLessonNavigationData(state),
    lessonTitle: getIsLessonTitle(state),
    topicId: getTopicId(state),
    lessonData: getLessonData(state),
    resetState: getResetState(state),
    getTimeLineZoomST: getTimeLineZoomST(state),
    getTimeLineZoomIndexST: getTimeLineZoomIndexST(state),
    getTimeLineMoveCountST: getTimeLineMoveCountST(state),
    getTimeLineCursorStatusST: getTimeLineCursorStatusST(state),
    getFirstTimeLineCursorStatusST: getFirstTimeLineCursorStatusST(state),
    getTimeLineEventDataST: getTimeLineEventDataST(state),
    getTimeLineMovePositionST: getTimeLineMovePositionST(state),
    getTimeLineMoveStatusST: getTimeLineMoveStatusST(state),
    getTimeTineZoomLevelST: getTimeTineZoomLevelST(state),
    getScreenShotLoadingST: getScreenShotLoadingST(state),
    getMapCenterCoordinatesST: getMapCenterCoordinatesST(state),
    getMapCenterCoordinatesStatusST: getMapCenterCoordinatesStatusST(state),
    getScreenShotSendDataST: getScreenShotSendDataST(state),
    getScreenShotIntermediateDataST: getScreenShotIntermediateDataST(state),
    getScreenShotButtonClickST: getScreenShotButtonClickST(state),
    getTimeLineHeightST: getTimeLineHeightST(state),
    getBaseMapGroupIdST: getBaseMapGroupIdST(state),
    selectedMapStyle: getSelectedMapStyle(state),


});

const mapDispatchToProps = {
    setHints,
    setQuizPopup,
    setTimeLineExpend,
    getArticleFromAPI,
    setSelectedLesson,
    getTopicsFromAPI,
    getTopicNavigationFromAPI,
    setMenuExpend,
    setArticles,
    changeMapStyles,
    changeMapRequirements,
    setPainterStart,
    getLessonNavigationFromAPI,
    setLessonsTitle,
    setNextID,
    setPrevID,
    setResetState,
    loadingMap,
    dispatchMapCenterPositionStatusST,
    setTimeLineZoomST,
    setTimeLineZoomIndexST,
    setTimeLineMoveCountST,
    setTimeLineCursorStatusST,
    setTimeLineEventDataST,
    setTimeLienMovePos,
    setTimeLIneZoomLevelST,
    setScreenShotButtonStateST,
    setTimeLineHeightST,
    setFirstTimeLineCursorStatusSTData
};

export default connect(mapStateTopProps, mapDispatchToProps)(Timeline);
