import React, { memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import ColorCircle from '../../components/ColorCircle'

import {
  getPolygonDrawToolboxColorPickerState,
  getPolygonDrawToolboxColors,
  getPolygonDrawToolboxCurrentFillColor,
  getPolygonDrawToolboxCurrentFillOpacity,
  getPolygonDrawToolboxCurrentStrokeColor,
  getPolygonDrawToolboxCurrentStrokeOpacity,
} from '../../../../store/selectors'
import {
  setPolygonDrawToolboxColorPickerState,
  setPolygonDrawToolboxCurrentFillColor,
  setPolygonDrawToolboxCurrentFillOpacity,
  setPolygonDrawToolboxCurrentStrokeColor,
  setPolygonDrawToolboxCurrentStrokeOpacity,
} from '../../../../store/actions/painterStart'

import { setMapboxDrawToolboxGeoJSONState } from '../../../../store/actions/mapStateAction'

import { color_picker_data } from '../../../../utils/color_picker_data'

import CloseIcon from '../../../../assets/imgs/PaintBar/close-icon.svg'
import DeleteIcon from '../../../../assets/imgs/PaintBar/delete-trash-icon.svg'

import './style.css'

const PolygonDrawCreateEditPopup = (props) => {
  const {
    getPolygonDrawToolboxColors,
    setPolygonDrawToolboxColorPickerState,
    setPolygonDrawToolboxCurrentFillColor,
    getPolygonDrawToolboxCurrentFillColor,
    setPolygonDrawToolboxCurrentFillOpacity,
    getPolygonDrawToolboxCurrentFillOpacity,
    setPolygonDrawToolboxCurrentStrokeColor,
    getPolygonDrawToolboxCurrentStrokeColor,
    setPolygonDrawToolboxCurrentStrokeOpacity,
    getPolygonDrawToolboxCurrentStrokeOpacity,
    getPolygonDrawToolboxColorPickerState,
    modeChanger,
    handleClose,
    handleDelete,
    updateColorStatus,
  } = props

  const [isOpenColors, setIsOpenColors] = useState(false)
  const [isFill, setIsFill] = useState(true)

  const handleRangeChange = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (isFill) {
      setPolygonDrawToolboxCurrentFillOpacity(event.target.value / 100)
    } else {
      setPolygonDrawToolboxCurrentStrokeOpacity(event.target.value / 100)
    }
  }

  const toggleColorsDropdown = () => {
    if (getPolygonDrawToolboxColorPickerState && isOpenColors) {
      setPolygonDrawToolboxColorPickerState(false)
    }
    setIsOpenColors(!isOpenColors)
  }

  const colorSelectHandler = (e, color) => {
    e.preventDefault()
    e.stopPropagation()

    if (color.id === getPolygonDrawToolboxColors) {
      setPolygonDrawToolboxColorPickerState(
        !getPolygonDrawToolboxColorPickerState,
      )
      return
    }

    if (isFill) {
      setPolygonDrawToolboxCurrentFillColor(color.color)
    } else {
      setPolygonDrawToolboxCurrentStrokeColor(color.color)
    }

    updateColorStatus(color.id)
  }

  const colorPickerColorSelectHandler = (e, color) => {
    e.preventDefault()
    e.stopPropagation()

    if (isFill) {
      setPolygonDrawToolboxCurrentFillColor(color)
    } else {
      setPolygonDrawToolboxCurrentStrokeColor(color)
    }
    updateColorStatus(6)
    setPolygonDrawToolboxColorPickerState(false)
  }

  useEffect(() => {
    setPolygonDrawToolboxColorPickerState(false)
  }, [isFill])

  return (
    <>
      <div
        className="draw-polygons-popup-container_color-picker"
        onClick={toggleColorsDropdown}>
        <span className={'current-picked-color'} onClick={toggleColorsDropdown}>
          <ColorCircle
            updateColorStatus={updateColorStatus}
            color={{
              color: isFill
                ? getPolygonDrawToolboxCurrentFillColor
                : getPolygonDrawToolboxCurrentStrokeColor,
            }}
            status={true}
          />
        </span>
      </div>
      <div className="draw-polygons-popup-container_actions">
        <span
          className={'delete-icon-wrapper'}
          onClick={() => handleDelete('polygon')}>
          <img className={'delete-icon'} src={DeleteIcon} alt="Delete" />
        </span>
        <span
          className={'close-icon-wrapper'}
          onClick={() => handleClose('polygon')}>
          <img className={'close-icon'} src={CloseIcon} alt="Close" />
        </span>
      </div>

      {isOpenColors && getPolygonDrawToolboxColorPickerState && (
        <div
          className="polygon_draw_tools_color_item_container"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          {color_picker_data.map((color, i) => (
            <div
              key={i}
              className="polygon_draw_tools_color_item"
              style={{ backgroundColor: color }}
              onClick={(e) => colorPickerColorSelectHandler(e, color)}></div>
          ))}
          <span></span>
        </div>
      )}

      {isOpenColors && (
        <div className={'color-and-opacity-picker-popup'}>
          <div className={'fill-stroke-buttons'}>
            <div
              className={`fill-stroke-button ${!isFill && 'active'}`}
              onClick={() => setIsFill(false)}>
              Եզրագիծ
            </div>
            <div
              className={`fill-stroke-button ${isFill && 'active'}`}
              onClick={() => setIsFill(true)}>
              Լցվածություն
            </div>
          </div>
          <div className={'opacity-selector'}>
            <div className="range-container">
              <input
                className="custom-slider"
                type="range"
                min="0"
                max="100"
                value={
                  isFill
                    ? getPolygonDrawToolboxCurrentFillOpacity * 100
                    : getPolygonDrawToolboxCurrentStrokeOpacity * 100
                }
                onChange={handleRangeChange}
              />
              <div className="checkerboard" />
              <div
                className={'background'}
                style={{
                  background: `linear-gradient(to right, transparent, ${isFill ? getPolygonDrawToolboxCurrentFillColor : getPolygonDrawToolboxCurrentStrokeColor})`,
                }}
              />
            </div>
          </div>
          <div className={'colors-row'}>
            {getPolygonDrawToolboxColors.map((color, i) => {
              return (
                <div key={i} className="polygon_tool_color_data_item">
                  {i === getPolygonDrawToolboxColors.length - 1 ? (
                    <div
                      className={'available-color'}
                      style={{ height: 25 }}
                      onClick={() => {
                        setPolygonDrawToolboxColorPickerState(
                          !getPolygonDrawToolboxColorPickerState,
                        )
                      }}>
                      <svg
                        style={{
                          background:
                            'conic-gradient( from 90deg, rgba(102, 70, 232, 1) 25%, rgba(86, 177, 68, 1) 49%, rgba(203, 197, 68, 1) 75%, rgba(232, 67, 61, 1) 100% )',
                          borderRadius: '50%',
                          height: 25,
                          width: 25,
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                      />
                    </div>
                  ) : (
                    <div
                      className={'available-color'}
                      onClick={(e) => colorSelectHandler(e, color)}>
                      <ColorCircle
                        updateColorStatus={updateColorStatus}
                        status={
                          color.color ===
                          (isFill
                            ? getPolygonDrawToolboxCurrentFillColor
                            : getPolygonDrawToolboxCurrentStrokeColor)
                        }
                        color={color}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  getPolygonDrawToolboxColors: getPolygonDrawToolboxColors(state),
  getPolygonDrawToolboxColorPickerState:
    getPolygonDrawToolboxColorPickerState(state),
  getPolygonDrawToolboxCurrentFillColor:
    getPolygonDrawToolboxCurrentFillColor(state),
  getPolygonDrawToolboxCurrentFillOpacity:
    getPolygonDrawToolboxCurrentFillOpacity(state),
  getPolygonDrawToolboxCurrentStrokeColor:
    getPolygonDrawToolboxCurrentStrokeColor(state),
  getPolygonDrawToolboxCurrentStrokeOpacity:
    getPolygonDrawToolboxCurrentStrokeOpacity(state),
})

const mapDispatchToProps = {
  setPolygonDrawToolboxColorPickerState: setPolygonDrawToolboxColorPickerState,
  setPolygonDrawToolboxCurrentFillColor: setPolygonDrawToolboxCurrentFillColor,
  setPolygonDrawToolboxCurrentFillOpacity:
    setPolygonDrawToolboxCurrentFillOpacity,
  setPolygonDrawToolboxCurrentStrokeColor:
    setPolygonDrawToolboxCurrentStrokeColor,
  setPolygonDrawToolboxCurrentStrokeOpacity:
    setPolygonDrawToolboxCurrentStrokeOpacity,
  setMapboxDrawToolboxGeoJSONState: setMapboxDrawToolboxGeoJSONState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(PolygonDrawCreateEditPopup))
