import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import hy from './hy.json';
import en from './en.json';

const resources = {
    hy: { translation: hy },
    en: { translation: en },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'hy',
    fallbackLng: 'hy',
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
