import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AccountDashboard from "../../components/accountDashboard";
import LeftNavigation from "../../components/leftNavigation";
import { HandleToken, LogOut } from "../../helpers";
import {
  getFilteredSubjectsFromAPI,
  loadingSubjects,
  setSubjects,
} from "../../store/actions/subjects";
import Account from "../account";
import AccountFooter from "./../../components/accountFooter/index";
import "./style.css";

import AccountBoard from "../../components/UI/AccountBoards";
import AccountPage from "../../components/UI/AccountPage";
import Bookmark from "../../components/UI/Bookmark";
import Logout from "../../components/UI/LogOut";
import { getFilteredGradesFromAPI } from "../../store/actions/grades";
import { getLessonsFromAPI } from "../../store/actions/lessons";
import { getTopicsFromAPI } from "../../store/actions/topics";
import { getUserFromAPI } from "../../store/actions/user";
import {
  getFilteredGradesData,
  getFilteredSubjectsData,
  getIsFilteredGradeLoading,
  getIsFilteredSubjectLoading,
  getIsLessonLoading,
  getIsTopicLoading,
  getLessonsData,
  getTopicsData,
  getUserData,
} from "../../store/selectors";
import {
  constructSearchApiParams,
  constructSelectedItemApiParams,
} from "./utils";
import DynamicScreenshot from "../../components/UI/DynamicScreenshot";
import { isMobile } from "react-device-detect";
import {LanguageChanger} from "../../components/languageChanger";
import {withTranslation} from "react-i18next";
import PresentationIcon from "../../components/UI/Presentation";

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class AccountBoards extends Component {
  constructor() {
    super();
    this.state = {
      subjects: null,
      selectedSubjectID: null,
      grades: null,
      selectedGradeID: null,
      topics: null,
      selectedTopicID: null,
      lessons: null,
      selectedLessonID: null,
    };
  }

  componentDidMount() {
    HandleToken();
    const { subjects, isSubjectsLoading, user } = this.props;
    // if (!user) {
    //   this.props.getUserFromAPI();
    // }

    if (subjects.length === 0 && isSubjectsLoading === false) {
      this.makeSubjectsCall();
    }
  }
  makeSubjectsCall() {
    const { url, body } = constructSearchApiParams("subject");
    this.props.getFilteredSubjectsFromAPI(url, body);
  }

  handleSelectItem = (type) => (id) => {
    if (type === "lesson") {
      return this.setState({
        selectedLessonID: id,
      });
    }
    const { url, body } = constructSelectedItemApiParams(
      type,
      id,
      this.state.selectedSubjectID
    );
    if (type === "subject") {
      this.props.getFilteredGradesFromAPI(url, body);
      this.setState({
        selectedSubjectID: id,
        selectedGradeID: null,
        selectedTopicID: null,
        selectedLessonID: null,
      });
    }
    if (type === "grade") {
      this.props.getTopicsFromAPI(url,  {...body,  sort: [
          {
            field: "order",
            type: "asc",
          },
        ]});
      this.setState({
        selectedGradeID: id,
        selectedTopicID: null,
        selectedLessonID: null,
      });
    }
    if (type === "topic") {
      this.props.getLessonsFromAPI(url, body);
      this.setState({
        selectedTopicID: id,
        selectedLessonID: null,
      });
    }
  };

  searchFilter = (type) => (val) => {
    const { selectedTopicID, selectedSubjectID, selectedGradeID } = this.state;
    const { url, body } = constructSearchApiParams(
      type,
      val,
      selectedTopicID,
      selectedSubjectID,
      selectedGradeID
    );

    if (type === "subject") {
      this.props.getFilteredSubjectsFromAPI(url, body);
    }
    if (type === "grade") {
      this.props.getFilteredGradesFromAPI(url, body);
    }
    if (type === "topic") {
      this.props.getTopicsFromAPI(url, body);
    }
    if (type === "lesson") {
      this.props.getLessonsFromAPI(url, body);
    }
  };

  handleGoToMapView = (type, id) =>
    window.open(`/map?type=${type}&${type}ID=${id}`, "_blank");

  render() {
    const {
      subjects,
      grades,
      topics,
      lessons,
      isSubjectsLoading,
      isGradesLoading,
      isTopicsLoading,
      isLessonLoading,
      t
    } = this.props;

    return (
      <Account onlyBG={true}>
        <LeftNavigation />
        <div className="top-btns">
          <div className="top-btns-left-bar">
            <div className={`main__tab active`}>
              <Link to="/account-boards">
                <div className="main__tab-btn">
                  <AccountBoard />
                </div>
                <div className="main__tab-btn__title activeTitle">
                  {t('topicsAndLesson')}
                </div>
              </Link>
            </div>
            <div className={`main__tab bookmarks__main-tab`}>
              <Link to="/bookmarks?page=1">
                <div className="main__tab-btn">
                  <Bookmark />
                </div>
                <div className="main__tab-btn__title">
                  {t('favoriteArticles')}
                </div>
              </Link>
            </div>
            {window.innerWidth > 1024 &&
            (<div className={`main__tab dynamic-screenshot__main-tab`}>
              <Link to="/presentations?page=1">
                <div className="main__tab-btn">
                  <PresentationIcon />
                </div>
                <div className="main__tab-btn__title">
                  {t('presentations')}
                </div>
              </Link>
            </div>)}
            {window.innerWidth > 1024  &&
                (<div className={`main__tab dynamic-screenshot__main-tab`}>
                  <Link to="/dynamic-screenshots?page=1">
                    <div className="main__tab-btn">
                      <DynamicScreenshot/>
                    </div>
                    <div className="main__tab-btn__title">
                      {t('savedMaps')}
                    </div>
                  </Link>
                </div>)}
            </div>
          <div className="top-btns-right-bar">
            <div className={`main__tab`}>
              <Link to="/account-page">
                <div className="main__tab-btn">
                  <AccountPage />
                </div>
                <div className="main__tab-btn__title">
                  {t('userPage')}
                </div>
              </Link>
            </div>
            <div className={`main__tab`}>
              <button type="button" onClick={LogOut}>
                <div className="main__tab-btn">
                  <Logout />
                </div>
                <div className="main__tab-btn__title">
                  {t('signOut')}
                </div>
              </button>
            </div>
            {/*<LanguageChanger />*/}
          </div>
        </div>
        <div className="account-dashboards">
          <AccountDashboard
            width={"20%"}
            color={"#95d4f3"}
            heading={t('subject')}
            type={"subject"}
            isLoading={isSubjectsLoading}
            data={subjects}
            selectedItem={this.state.selectedSubjectID}
            selectItem={this.handleSelectItem("subject")}
            searchFilter={this.searchFilter("subject")}
          />
          {this.state.selectedSubjectID !== null ? (
            <AccountDashboard
              width={"18%"}
              color={"#f3bfda"}
              heading={t('grade')}
              type={"grade"}
              isLoading={isGradesLoading}
              data={grades.filter(el=>el.subjects.includes(this.state.selectedSubjectID))}
              selectedItem={this.state.selectedGradeID}
              selectItem={this.handleSelectItem("grade")}
              searchFilter={this.searchFilter("grade")}
            />
          ) : null}
          {this.state.selectedGradeID !== null ? (
            <AccountDashboard
              width={"27%"}
              color={"#ffde5a"}
              heading={t('topic')}
              type={"topic"}
              isLoading={isTopicsLoading}
              data={topics}
              selectedItem={this.state.selectedTopicID}
              selectItem={this.handleSelectItem("topic")}
              searchFilter={this.searchFilter("topic")}
              goToMap={(type, id) => this.handleGoToMapView(type, id)}
            />
          ) : null}
          {this.state.selectedTopicID !== null ? (
            <AccountDashboard
              width={"27%"}
              color={"#42d3b0"}
              heading={t('lesson')}
              type={"lesson"}
              isLoading={isLessonLoading}
              data={lessons}
              selectedItem={this.state.selectedLessonID}
              selectItem={this.handleSelectItem("lesson")}
              searchFilter={this.searchFilter("lesson")}
              goToMap={(type, id) => this.handleGoToMapView(type, id)}
            />
          ) : null}
        </div>
        <AccountFooter />
      </Account>
    );
  }
}

const mapStateTopProps = (state) => ({
  subjects: getFilteredSubjectsData(state),
  grades: getFilteredGradesData(state),
  user: getUserData(state),
  topics: getTopicsData(state),
  lessons: getLessonsData(state),
  isSubjectsLoading: getIsFilteredSubjectLoading(state),
  isGradesLoading: getIsFilteredGradeLoading(state),
  isTopicsLoading: getIsTopicLoading(state),
  isLessonLoading: getIsLessonLoading(state),
});

const mapDispatchToProps = {
  getFilteredSubjectsFromAPI,
  getFilteredGradesFromAPI,
  getLessonsFromAPI,
  getTopicsFromAPI,
  getUserFromAPI,
  loadingSubjects,
  setSubjects,
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withTranslation()(withNavigation(AccountBoards)));
